/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from "../new-color-axiosInstance";
import { ListData, CountyData, AddAddress, PostCodeAddress, GoogleAddress } from "./address.interface";

/*
 * https://joyspace.jd.com/pages/iFAEYMJLLB1eAX3tEDDH
 * @interface Data
 */

interface Response<T> {
  success: boolean;
  code: string;
  msg: string;
  data: T;
  traceId: string;
  result: boolean;
}

// 查询用户地址列表
export const getUserAddressList = (params?: {source: number}): Promise<Response<ListData>> => {
  // 设置默认值为 0
  const finalParams = { source: 0, ...params };
  return axiosInstance
    .get<Response<ListData>>(`user_address_getList`, { params: finalParams } )
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 查询county数据列表（地址新增页面-下拉选数据）
export const getCountyList = (): Promise<CountyData> => {
  return axiosInstance.get<CountyData>(`user_address_getCounties`)
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 新增用户地址
export const addAddress = (requestData: AddAddress): Promise<Response<any>> => {
  return axiosInstance
    .post<Response<any>>(`user_address_add`,  undefined, {params: {...requestData}}, false)
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 更新用户地址
export const updateAddress = (requestData: AddAddress): Promise<Response<any>> => {
  return axiosInstance
    .post<Response<any>>(`user_address_modify`, undefined, {params: {...requestData}}, false)
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 删除用户地址
export const deleteAddress = (requestData: { addressId: number }): Promise<Response<any>> => {
  return axiosInstance
    .post<Response<any>>(`user_address_delete`, undefined, {params: requestData}, false)
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 查询postcode接口（用于校验postcode是否有效及获取四级地址）
export const queryPostCode = (requestData: {
  postCode: string;
  countryId?: 300000;
}): Promise<Response<PostCodeAddress>> => {
  return axiosInstance
    .get<Response<PostCodeAddress>>(`user_address_getPostCode`, { params: requestData }, false)
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 地址自动补全接口
export const autocomplete = (requestData: { searchText: string }): Promise<Response<any>> => {
  return axiosInstance
    .get<Response<any>>(`user_address_autoComplete`, { params: requestData }, false)
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 地址详细信息接口
export const placeDetail = (requestData: { placeId: string }): Promise<Response<any>> => {
  return axiosInstance
    .get<Response<any>>(`user_address_getDetail`, { params: requestData }, false)
    .then((response) => response.data)
    .catch((error: any) => error);
};

// 删除用户联登草稿地址 v315
export const deleteDraftAddress = (requestData: { addressId: number }): Promise<Response<any>> => {
  return axiosInstance
    .post<Response<any>>(`user_address_deleteDraft`, undefined, {params: requestData}, false)
    .then((response) => response.data)
    .catch((error: any) => error);
};
