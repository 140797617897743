/* eslint-disable react/display-name */
import * as T from "../../type/index";
import LoginDialog from "../Dialog/index";
import style from "../../style.module.scss";
import cookieUtils from "@/utils/cookieUtils";
import loginConfig from "@/constants/loginConfig";
import React, { useState, useEffect } from "react";
import ReceiveTheCodeDom from "./ReceiveTheCodeDom";
import LoginType from "@/common-components-src/const/login";
import Edit  from "@/common-components-src/assets/icons/Edit.svg";
import Closed  from "@/common-components-src/assets/icons/Closed.svg";
import UKToast from "@/common-components-src/components/UKToast/index";
import RedClose  from "@/common-components-src/assets/icons/redClose.svg";
import CheckPsd  from "@/common-components-src/assets/icons/CheckPsd.svg";
import NoCheckPsd  from "@/common-components-src/assets/icons/NoCheckPsd.svg";
import LoadingIcon from '@/common-components-src/components/loadingIcon/index';
import PasswordError  from "@/common-components-src/assets/icons/passwordError.svg";
import PasswordSuccess  from "@/common-components-src/assets/icons/passwordSuccess.svg";


import { FormControlInputStyle } from "../../style/style";
import { fetchSettingsConsent } from "@/apis/help/help.api";
import { extractEmailParams, splitAndProcess } from "../../utils/login";
import { setDefaultLocalAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import { storePhoneNumberAndCodeTime, parsePhoneNumberAndCodeTime } from "../../utils/login";
import { GoogleReCAPTCHAEnum, CaptchaActionEnum } from "@/common-components-src/js/utils/login/constants";
import { loginOrRegisterByOTP, sendOTP, EmailRegister, EmailLoginOTP, judgeNewOrOldUserApi } from "@/common-components-src/api/login/index.api";
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  DialogContent,
} from "@mui/material";

import { sendClickTrack as emailPasswordSendClickTrack, sendKeyActionTrack as emailPasswordSendKeyActionTrack, EXPOSURE_RECORD as EMAIL_PASSWORD_EXPOSURE_RECORD } from "@/tracks/25290";
import { sendClickTrack as msgCodeSendClickTrack, sendKeyActionTrack as msgCodeSendKeyActionTrack, EXPOSURE_RECORD as MSG_CODE_EXPOSURE_RECORD } from "@/tracks/25277"
interface PropsType {
  closeDialog?: () => void;
  callback: (props?: any) => void; // 登录成功后的回调函数
  goLoginDefault: (props?: T.propsDataType) => void; // 跳转到登录页的函数
  ForgotPasswordFn: (props?: T.propsDataType) => void;

  propsData: T.propsDataType; // 其他数据
  props: { isRefresh?: boolean; isForgotPassword?: boolean; isReturnUrl?: boolean }; // 登录时传入参数 控制是否强制刷新页面等
  // ref?: React.RefObject<HTMLDivElement>;
}

const PhoneEmailLogin = React.forwardRef(({ props, callback, propsData, goLoginDefault, ForgotPasswordFn, closeDialog }: PropsType, ref) => {
  const urlExtParams = splitAndProcess();
  const [returnUrl, setReturnUrl] = useState("/");
  const __w: any = window;
  React.useImperativeHandle<any, any>(ref, () => ({
    onReturnFn: () => {
      if (seconds != 0) {
          cookieUtils.setSessionStorage('phoneAndCodeTime', `${propsData.identifier}|${seconds}`);
          cookieUtils.setSessionStorage("codeCloseTime", String(new Date().getTime() / 1000));
      }
    }
  }));

  useEffect(() => {
    if (propsData.phoneOrEmail === "phone" && !propsData.phoneCodeTime) {
      updateTimer();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get("returnUrl");

    url && setReturnUrl(url);
  }, []);

  /* -------------------------------------------------------------- 验证码模块 -------------------------------------------------------------- */
  const [seconds, setSeconds] = useState(0);
  const [codeValue, setCodeValue] = useState(""); // 验证码
  const [codeError, setCodeError] = useState(""); // 验证码错误提示
  const [resendCode, setResendCode] = useState("60s"); // 按钮文字
  const [btnLoading, setBtnLoading] = useState(false); // 按钮是否加载中
  const [secondsFlag, setSecondsFlag] = useState(false); // 重复新发送验证码按钮状态
  const [codeDisabled, setCodeDisabled] = useState(false); // 验证码
  const [receiveTheCodeOpen, setReceiveTheCodeOpen] = useState(false); // 是否打开没有验证码的弹框；
  let Timer: any = null;
  let secondsLeft = 60;

  useEffect(() => {
    if (propsData.phoneCodeTime) {
      setSecondsFlag(true);
      const data = parsePhoneNumberAndCodeTime();
      const currentTime = new Date().getTime() / 1000;
      const codeCloseTime = cookieUtils.getSessionStorage("codeCloseTime");
      const currentDate = Math.floor(currentTime - Number(codeCloseTime))
      // eslint-disable-next-line react-hooks/exhaustive-deps
      secondsLeft = Number(data.codeTime) - currentDate;
      // codeTime: 记录的返回的秒数 46；
      // currentDate：当前时间 - 返回时间； 已经过了多少秒； 46 - 10 = 35；
      if (secondsLeft > 0) {
          setSecondsFlag(true);
          setSeconds(secondsLeft);
          updateTimer();
      } else {
          setSeconds(60);
          updateTimer();
      }
    }
  }, [propsData]);

  // 监听验证码输入框值发生变化的时候；
  useEffect(() => {
    setCodeError("");
    if (codeValue.length > 6) {
      setCodeDisabled(true);
      setCodeError("The verification code you entered is incorrect. Please try again");
    } else if (/\D/.test(codeValue)) {
      setCodeDisabled(true);
      setCodeError("The verification code you entered is incorrect. Please try again");
    } else if (codeValue == "") {
      setCodeDisabled(true);
    } else if (codeValue.length == 6) {
      setCodeDisabled(false);
      setCodeError("");
    } else if (codeValue.length !== 6) {
      setCodeDisabled(true);
    }
  }, [codeValue]);
  const codeChange = (v: string) => {
    const newValue = v.replace(/[^0-9]/g, "");
    let value = v;
    if (value !== newValue) {
      value = newValue;
    } else {
      const codeNum = value;
      if (codeNum.length > 6) {
        value = value.slice(0, 6);
      }
      setCodeValue(value);
    }
  };
  // 倒计时
  const updateTimer = () => {
    setResendCode(`${secondsLeft}s`);
    Timer = setInterval(() => {
      secondsLeft--;
      if (secondsLeft > 0) {
        setSecondsFlag(true);
        setSeconds(secondsLeft);
        setResendCode(`${secondsLeft}s`);
      } else {
        cookieUtils.clearSessionStorage("codeCloseTime");
        // 清除手机号
        cookieUtils.clearSessionStorage("phoneAndCodeTime");
        setSecondsFlag(false);
        setResendCode(``);
        setSeconds(secondsLeft);
        clearInterval(Timer);
      }
    }, 1000);
  };
  // 点击验证码的 Sign In 按钮;
  const codeSignInSubmit = async () => {
    setBtnLoading(true);
    const { otpType, idPrefix, identifier, sessionId, stepCode } = propsData;
    const extParams = propsData.userType === "new" ? {extension: JSON.stringify({ ...urlExtParams })} : null;

    if (propsData.userType === "new") {
      /** 点击事件上报-注册按钮 */
      msgCodeSendClickTrack.MsgCode_Register({isNew: propsData.userType === "new" ? "1" : "0"})
    } else {
      /** 点击事件上报-登录按钮 */
      msgCodeSendClickTrack.MsgCode_SignIn({isNew: propsData.userType === "new" ? "1" : "0"})
    }

    try {
      const [err, res]: any = await loginOrRegisterByOTP({
        otpType,
        idPrefix,
        identifier,
        sessionId,
        stepCode,
        appId: LoginType.Login.LOGIN_APPID,
        verifyCode: codeValue,
        ...extParams
      });

      if (propsData.phoneOrEmail === "phone") {
        /** 点击事件上报-用户登录结果返回 */
        msgCodeSendClickTrack.MsgCode_LoginResult({isNew: propsData.userType === "new" ? "1" : "0", is_success: res?.success ? "1" : "0"})
        /** 关键事件上报-验证结果返回 */
        msgCodeSendKeyActionTrack.MsgCode_VerifyResult({isNew: propsData.userType === "new" ? "1" : "0", is_success: res?.success ? "1" : "0"})
        
      } else {
        /** 点击事件上报-用户登录结果返回 */
        msgCodeSendClickTrack.MsgCode_LoginResult({isNew: propsData.userType === "new" ? "1" : "0",  is_success: res?.success ? "1" : "0"})
        /** 关键事件上报-验证结果返回 */
        msgCodeSendKeyActionTrack.MsgCode_VerifyResult({isNew: propsData.userType === "new" ? "1" : "0", is_success: res?.success ? "1" : "0"})
      }

      // 验证码登录
      if (res && res.success) {
        // 关闭隐私协议变更通知弹窗
        await fetchSettingsConsent();

        cookieUtils.clearSessionStorage("codeCloseTime");
        cookieUtils.clearSessionStorage("phoneAndCodeTime");
        cookieUtils.setCookie(loginConfig.loginLocalInfo.USER_SUCCESS_ACCOUNT, propsData.idPrefix + "_" + propsData.identifier, 15, loginConfig.POSTFIX_DOMAIN);
        UKToast.show({
          icon: "success",
          content: res?.msg || "Login success",
        });
        cookieUtils.clearSessionStorage("codeCloseTime");
        cookieUtils.clearSessionStorage("phoneAndCodeTime");
        __w.JDIheader && __w.JDIheader.initUserInfo();
        __w.JDIheader && __w.JDIheader.initCartNum();
        await setDefaultLocalAddress();
        if (props && props.isRefresh && !props.isReturnUrl) location.reload();
        if (props && props.isForgotPassword && !props.isReturnUrl) location.href = "/";
        if (returnUrl && props.isReturnUrl) window.location.href = returnUrl;
        closeDialog && closeDialog();
        callback({
          loginSuccessData: res.data,
          login_type: "email_login",
          login_type_name: "login_success",
        });
        setBtnLoading(false);
      } else {
        UKToast.show({
          icon: "error",
          content: res?.msg,
        });
        setCodeError(res?.msg);
        setBtnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
    }
  };
  // 验证码输入框回车事件
  const codeKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!codeDisabled) {
      if (e.key == "Enter") {
        codeSignInSubmit();
      }
    }
  };
  // 重新发送验证码
  const phoneContinueFn = async () => {
    setCodeValue("");
    setCodeError("");
    getSessionIdSDk();
  };

  // 获取滑块 token
  const getSessionIdSDk = () => {
    judgeNewOrOldUserApi({
      otpType: 2,
      idPrefix: propsData?.idPrefix as string,
      identifier: propsData?.identifier,
    }).then((res: any) => {
    // 是否白名单 true:不需要生成token，不需要走google验证
      if (res && res.data?.wl) {
        sendOTPCode("");
      } else {
        const w: any = window;
        if (!w.grecaptcha) {
            UKToast.show({icon: "error", content: "Request Google Grecaptcha Enterprise Error"})
            return;
        }
        (w)?.grecaptcha.enterprise.ready(async () => {
            const token = await (w)?.grecaptcha.enterprise.execute(GoogleReCAPTCHAEnum.KEY_ID, {action: CaptchaActionEnum.SIGNUP});
            sendOTPCode(token);
        });
      }
    });
  };
  // 发送验证码
  const sendOTPCode = async (token: string | null) => {
    try {
      const params: any = {
        otpType: 2,
        idPrefix: propsData.idPrefix,
        identifier: propsData.identifier,
        scene: LoginType.VerifyCodeSceneEnum.LOGIN,
        captchaCode: token,
        captchaAction: CaptchaActionEnum.SIGNUP,
      };
      const [err, res]: any = await sendOTP(params);
      if (res && res.success) {
        UKToast.show({
          content: "Verification code sent successfully",
          icon: "success",
        });
        updateTimer();
      } else {
        UKToast.show({
          content: res.msg,
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 点击编辑
  const onEditFn = () => {

    /** 埋点-点击事件上报-编辑号码 */
    msgCodeSendClickTrack.MsgCode_Edit({isNew: propsData.identifier === "new" ? "1" : "0"})

    storePhoneNumberAndCodeTime(propsData.identifier, seconds, propsData.idPrefix);
            // 记录当前点击返回上一页的时间；
    if (seconds == 0) {
      propsData && goLoginDefault(propsData);
      cookieUtils.clearSessionStorage("codeCloseTime");
      cookieUtils.clearSessionStorage("phoneAndCodeTime");
      return;
    }
    const d = parsePhoneNumberAndCodeTime();
    const times: any = cookieUtils.getSessionStorage("codeCloseTime");
    if (!times && d.phoneNumber == propsData.identifier) {
      cookieUtils.setSessionStorage("codeCloseTime", new Date().getTime() / 1000);
    }
    propsData && goLoginDefault(propsData);
  };

  /* -------------------------------------------------------------- 邮箱模块 -------------------------------------------------------------- */
  const [psdValue, setPsdValue] = useState<string | null>(""); // 邮箱密码
  const [showPsd, setShowPsd] = useState<boolean>(true); // 邮箱密码是否显示
  const [psdError, setPsdError] = useState<string | null>(""); // 邮箱密码错误提示
  const [confirmPassword, setConfirmPassword] = useState(""); // 再次输入的密码
  const [errorConfirmText, setErrorConfirm] = useState(""); // 再次输入密码提示
  const [showConfirmPassword, setShowConfirmPassword] = useState(true); // 查看输入密码
  const [passwordMsgFlag, setPasswordMsgFlag] = useState(false); // 初始密码错误提示元素显示
  const [confirmPasswordMsgFlag, setConfirmPasswordMsgFlag] = useState(false); // 再次输入的密码

  const [minimumMsg, setMinimumMsg] = useState(false); // 密码长度提示
  const [atLeastOneNumberMsg, setAtLeastOneNumberMsg] = useState(false); // 最少一个数字
  const [atLeastOneLetterMsg, setAtLeastOneLetterMsg] = useState(false); // 最少一个字母
  const [psdDisabled, setPsdDisabled] = useState(true); // 邮箱密码是否禁用
  useEffect(() => {
    if (propsData && propsData.userType == "new") {
      if (minimumMsg && atLeastOneNumberMsg && atLeastOneLetterMsg && psdValue == confirmPassword) {
        setPsdDisabled(false);
      } else if (minimumMsg && atLeastOneNumberMsg && atLeastOneLetterMsg) {
        setPsdError("");
      } else {
        setPsdDisabled(true);
      }
    }
  }, [psdValue, minimumMsg, atLeastOneNumberMsg, atLeastOneLetterMsg, confirmPassword]);
  // 监听第一次输入密码的时候
  useEffect(() => {
    // if (/^\s+$/g.test(psdValue as string)) {
    //   return;
    // }
    propsData.userType == "new" && passwordConfirmValidation(psdValue);
    propsData.userType == "old" && passwordValidation(psdValue);

    if (propsData && propsData.userType == "new") {
      if (confirmPassword) {
        if (psdValue == confirmPassword) {
          setConfirmPasswordMsgFlag(true);
          setErrorConfirm("");
        } else {
          setConfirmPasswordMsgFlag(false);
          setErrorConfirm("Passwords must match"); // 两次输入的密码不一致
        }
      } else if (confirmPassword == "" && psdValue == "") {
        setConfirmPasswordMsgFlag(false);
        setErrorConfirm("");
      }
    }
  }, [psdValue]);
  // 监听再次输入密码的时候
  useEffect(() => {
    if (psdValue && confirmPassword) {
      if (confirmPassword !== psdValue) {
        setErrorConfirm("Passwords must match"); // 两次输入的密码不一致
        setConfirmPasswordMsgFlag(false);
      } else {
        setErrorConfirm("");
        setConfirmPasswordMsgFlag(true);
      }
    } else if (psdValue == "" && confirmPassword) {
      setErrorConfirm("Passwords must match");
    } else if (psdValue == "" && confirmPassword == "") {
      setErrorConfirm("");
    } else if (psdValue && confirmPassword == "") {
      setErrorConfirm("Passwords must match");
      setConfirmPasswordMsgFlag(false);
    }
  }, [confirmPassword]);
  // 邮箱密码校验
  const passwordValidation = (psdValue: string | null) => {
    if (psdValue) {
      setPsdDisabled(false);
      setPsdError("");
    } else {
      setPsdError("");
      setPsdDisabled(true);
    }
  };
  // 邮箱密码校验
  const passwordConfirmValidation = (psdValue: any) => {
    if (psdValue || /^\s+$/.test(psdValue)) {
      setPasswordMsgFlag(true);
      if (!/[0-9]/.test(psdValue)) {
        setPsdError("At least one number");
        setAtLeastOneNumberMsg(false);
      } else {
        setAtLeastOneNumberMsg(true);
      }
      if (!/[a-zA-Z]/.test(psdValue)) {
        setPsdError("At least one letter");
        setAtLeastOneLetterMsg(false);
      } else {
        setAtLeastOneLetterMsg(true);
      }
      if (psdValue.length < 8) {
        setPsdError("8 characters minimum");
        setMinimumMsg(false);
      } else {
        setMinimumMsg(true);
      }
    } else {
      setPsdError("");
      setAtLeastOneNumberMsg(false);
      setAtLeastOneLetterMsg(false);
      setMinimumMsg(false);
    }
  };
  // 查看、隐藏密码
  const handleClickShowPassword = () => setShowPsd((show) => !show);

  // 删除文本前后空格
  function trimFirstAndLastSpace(str: any) {
    return str.replace(/^ | $/g, "");
  }
  // 查看/隐藏二次密码
  const handleShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  // 邮箱点击 sign in 按钮;
  const psdSignInSubmit = async () => {

    if (propsData.userType === "new") {
      /** 埋点-邮箱-点击事件上报-注册按钮 */
      emailPasswordSendClickTrack.EmailPassword_Register();
    } else {
      /** 点击事件上报-登录按钮 */
      emailPasswordSendClickTrack.EmailPassword_SignIn();
    }

    setPsdError("");
    setBtnLoading(true);
    const data: any = await extractEmailParams({
      ...propsData,
      password: trimFirstAndLastSpace(psdValue),
    });
    try {
      const [err, res]: any = propsData.userType === "new" ? await EmailRegister({
        ...data,
        extension: JSON.stringify({ ...urlExtParams })
        , // 邀请码
      }) : await EmailLoginOTP({
        ...data,
      });
      if (res && res.success) {
        /** 关键事件上报-用户登录结果返回 */
        emailPasswordSendKeyActionTrack.EmailPassword_LoginResult({ isNew: propsData?.userType === "new" ? "1" : "0", is_success: res?.success ? "1" : "0" })
        // 关闭隐私协议变更通知弹窗
        await fetchSettingsConsent();
        cookieUtils.setCookie(loginConfig.loginLocalInfo.USER_SUCCESS_ACCOUNT, propsData.identifier, 15, loginConfig.POSTFIX_DOMAIN);
        UKToast.show({
          content: "Login success",
          icon: "success",
        });
        await setDefaultLocalAddress();
        setPsdError("");
        __w.JDIheader && __w.JDIheader.initUserInfo();
        __w.JDIheader && __w.JDIheader.initCartNum();
        if (props && props.isRefresh && !props.isReturnUrl) location.reload();
        if (props && props.isForgotPassword && !props.isReturnUrl) location.href = "/";
        if (returnUrl && props.isReturnUrl) window.location.href = returnUrl;
        closeDialog && closeDialog();
        callback({
          loginSuccessData: res.data,
          login_type: "email_login",
          login_type_name: "login_success",
        });
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        // setPsdError(res.msg);
        UKToast.show({
          content: res.msg,
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
    }
  };
  const emailKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!psdDisabled) {
      if (e.key == "Enter") {
        psdSignInSubmit();
      }
    }
  };
  /* -------------------------------------------------------------- 忘记密码 -------------------------------------------------------------- */
  // 忘记密码
  const ForgotPassword = () => {
    ForgotPasswordFn && ForgotPasswordFn(propsData);
  };
  return (
    <>
      <div className={style.phoneEmailLogin}>
        <div className={style.title__box}>
          <div className={style.title}>{propsData.userType === "new" ? "Create your Joybuy account" : "Sign in with your account"}</div>
          <div className={style.title__text}>{`Don't just buy, Joybuy`}</div>
        </div>
        {/* 手机号登录 */}
        {propsData.phoneOrEmail === "phone" && (
          <div className={style.verificationCode__box}>
            <div className={style.code__title}>
              We have sent the code to {propsData.idPrefix} {propsData.identifier}
              <span 
                /** 编辑号码曝光 */
                exptag={"exp|" + MSG_CODE_EXPOSURE_RECORD.MsgCode_EditExpo}
                data-exptag-json-param={JSON.stringify({isNew: propsData.userType === "new" ? '1' : '0'})}
                data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                className={style.edit} 
                onClick={onEditFn}
              >
                Edit
              </span>
            </div>
            <div className={style.code__input}>
              <TextField
                multiline
                error={!!codeError}
                helperText={codeError}
                value={codeValue}
                color="info"
                type="text"
                margin="normal"
                variant="outlined"
                id="outlined-basic"
                label="Verification code"
                aria-describedby="custom-css-outlined-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => codeChange(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => codeKeyDown(e)}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {codeValue ? (
                          <>
                            <IconButton
                              onClick={() => {
                                setCodeError("");
                                setCodeValue("");
                                setPsdDisabled(true);
                              }}
                            >
                              <Closed />
                            </IconButton>
                          </>
                        ) : null}
                      </InputAdornment>
                    ),
                  },
                }}
                sx={{
                  width: "400px",
                  "& .MuiInputBase-root": {
                    height: "48px",
                  },
                }}
              />
              <div
                /** 未收到验证码曝光 */
                exptag={"exp|" + MSG_CODE_EXPOSURE_RECORD.MsgCode_NOTRecieveExpo}
                data-exptag-json-param={JSON.stringify({isNew: propsData.userType === "new" ? '1' : '0'})}
                data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}' 
                className={style.receive_the_code} 
                onClick={() => {

                  /** 点击事件上报-未收到验证码 */
                  msgCodeSendClickTrack.MsgCode_NOTRecieve({isNew: propsData.userType === "new" ? "1" : "0"})
                  
                  setReceiveTheCodeOpen(true)
                }}>
                {`Didn't receive the code ?`}
              </div>
              <div
                /** 再次发送按钮点击曝光 */
                exptag={"exp|" + MSG_CODE_EXPOSURE_RECORD.MsgCode_ResendExpo}
                data-exptag-json-param={JSON.stringify({isNew: propsData.userType === "new" ? '1' : '0'})}
                data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
                className={secondsFlag ? style.ResendCode__time : style.ResendCode}
                onClick={() => {
                  /** 点击事件上报-再次发送按钮点击 */
                  msgCodeSendClickTrack.MsgCode_Resend({isNew: propsData.userType === "new" ? "1" : "0"})

                  if (!secondsFlag) {
                    cookieUtils.setSessionStorage("codeCloseTime", new Date().getTime() / 1000);
                    phoneContinueFn();
                  }
                }}
              >
                Resend code {seconds != 0 ? resendCode : ""}
              </div>
              <Button
                variant="contained"
                disableElevation
                disabled={codeDisabled || btnLoading}
                onClick={codeSignInSubmit}
                startIcon={btnLoading ? <LoadingIcon /> : null}
                /** 登录，注册按钮曝光 */
                exptag={propsData.userType == "new" ? "exp|" +  MSG_CODE_EXPOSURE_RECORD.MsgCode_RegisterExpo : "exp|" +  MSG_CODE_EXPOSURE_RECORD.MsgCode_SignInExpo }
                data-exptag-config='{"useClick": false, "stay_duration": 0 "repeated":false, "area_rate": 0.01}'
                sx={{
                  width: "100%",
                  height: "48px",
                  fontSize: "20px",
                  margin: "25px 0",
                  backgroundColor: "#CC0C1C", // 自定义背景颜色
                  color: "#fff", // 自定义文字颜色
                  textTransform: "none",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#990915", // 悬停时的背景颜色
                  },
                  "&.Mui-disabled": {
                    backgroundColor: "#FF9FA7",
                    color: "#fff",
                  },
                }}
              >
                {propsData.userType === "new" ? "Register" : "Sign in"}
              </Button>
            </div>
          </div>
        )}
        {/* 邮箱登录 */}
        {propsData.phoneOrEmail === "email" && (
          <div className={style.email__box}>
            {/* 邮箱输入框 */}
            <FormControl sx={{ ...FormControlInputStyle }} variant="outlined" margin="normal">
              <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
              <OutlinedInput
                type="text"
                disabled
                defaultValue={propsData.identifier}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        goLoginDefault(propsData);
                        setPsdDisabled(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </InputAdornment>
                }
              />
              {/* {!!error && <FormHelperText>{error}</FormHelperText>} */}
            </FormControl>
            {/* 邮箱输入首次密码 */}
            <FormControl margin="normal" error={!!psdError} sx={{ ...FormControlInputStyle }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                color="info"
                value={psdValue}
                type={showPsd ? "password" : "text"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPsdValue(e.target.value)}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => emailKeyDown(e)}
                autoComplete="new-password"
                sx={{
                  "& input[type='password']::-ms-reveal": {
                    display: "none",
                  },
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {psdValue ? (
                      <>
                        <IconButton
                          onClick={() => {
                            setPsdError("");
                            setPsdValue("");
                          }}
                        >
                          <Closed />
                        </IconButton>
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                          onMouseUp={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                        >
                          {showPsd ? <NoCheckPsd /> : <CheckPsd />}
                        </IconButton>
                      </>
                    ) : null}
                  </InputAdornment>
                }
              />
              {propsData.userType == "old" && !!psdError && (
                <FormHelperText sx={{ color: "#CC0C1C" }}>
                  <div className={style.errorText}>
                    <RedClose />
                    <span>{psdError}</span>
                  </div>
                </FormHelperText>
              )}
            </FormControl>
            {passwordMsgFlag ? (
              <ul className={style.errorPasswordBox}>
                <li className={minimumMsg ? style.successMsg : style.errorMsg}>{minimumMsg ? <PasswordSuccess /> : <PasswordError />} 8 characters minimum</li>
                <li className={atLeastOneNumberMsg ? style.successMsg : style.errorMsg}>
                  {atLeastOneNumberMsg ? <PasswordSuccess /> : <PasswordError />} At least one number
                </li>
                <li className={atLeastOneLetterMsg ? style.successMsg : style.errorMsg}>
                  {atLeastOneLetterMsg ? <PasswordSuccess /> : <PasswordError />} At least one letter
                </li>
              </ul>
            ) : (
              ""
            )}
            {/* 再次输入邮箱密码 */}
            {propsData && propsData.userType === "new" && (
              <FormControl sx={{ ...FormControlInputStyle }} variant="outlined" margin="normal" error={!!errorConfirmText}>
                <InputLabel htmlFor="outlined-adornment-password">Re-enter password</InputLabel>
                <OutlinedInput
                  color="info"
                  value={confirmPassword}
                  type={showConfirmPassword ? "password" : "text"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => emailKeyDown(e)}
                  autoComplete="new-password"
                  endAdornment={
                    <InputAdornment position="end">
                      {confirmPassword ? (
                        <>
                          <IconButton
                            onClick={() => {
                              setErrorConfirm("");
                              setConfirmPassword("");
                              setPsdDisabled(true);
                              // setConfirmPasswordMsgFlag(false);
                            }}
                          >
                            <Closed />
                          </IconButton>
                          <IconButton
                            onClick={handleShowConfirmPassword}
                            onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                            onMouseUp={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                          >
                            {showConfirmPassword ? <NoCheckPsd /> : <CheckPsd />}
                          </IconButton>
                        </>
                      ) : null}
                    </InputAdornment>
                  }
                  sx={{
                    "& input[type='password']::-ms-reveal": {
                      display: "none",
                    },
                  }}
                />
                {!!errorConfirmText && (
                  <FormHelperText sx={{ color: "#CC0C1C" }}>
                    <div className={style.errorText}>
                      <PasswordError />
                      <span>{errorConfirmText}</span>
                    </div>
                  </FormHelperText>
                )}
                {confirmPasswordMsgFlag && (
                  <div className={style.successText}>
                    <PasswordSuccess />
                    <span>Passwords must match</span>
                  </div>
                )}
              </FormControl>
            )}

            {/* 忘记密码 */}
            {propsData.userType === "old" && (
              <div className={style.resetPsd}>
                <span 
                  exptag={"exp|" +  EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_ForgetExpo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0 "repeated":false, "area_rate": 0.01}'
                onClick={() => {
                  /** 点击事件上报-忘记密码选项 */
                  emailPasswordSendClickTrack.EmailPassword_Forget();
                  ForgotPassword()
                }}>Forgot your password?</span>
              </div>
            )}
            <Button
              variant="contained"
              disableElevation
              disabled={psdDisabled || btnLoading}
              onClick={psdSignInSubmit}
              startIcon={btnLoading ? <LoadingIcon /> : null}
              /** 登录，注册按钮曝光 */
              exptag={propsData.userType == "new" ? "exp|" +  EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_RegisterExpo : "exp|" +  EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_SignInExpo }
              data-exptag-config='{"useClick": false, "stay_duration": 0 "repeated":false, "area_rate": 0.01}'
              sx={{
                width: "100%",
                height: "48px",
                fontSize: "20px",
                margin: "25px 0",
                color: "#fff", // 自定义文字颜色
                backgroundColor: "#CC0C1C", // 自定义背景颜色
                textTransform: "none",
                boxShadow: "none",
                "&:hover": {
                  backgroundColor: "#990915", // 悬停时的背景颜色
                },
                "&.Mui-disabled": {
                  backgroundColor: "#FF9FA7",
                  color: "#fff",
                },
              }}
            >
              {propsData.userType === "new" ? "Register" : "Sign in"}
            </Button>
          </div>
        )}
      </div>
      {/* 手机 没有收到验证码 弹框 */}
      <LoginDialog
        open={receiveTheCodeOpen}
        showCloseBtn={true}
        onClose={(e: React.MouseEvent<HTMLButtonElement>, r: string) => setReceiveTheCodeOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "500px",
            maxHeight: "380px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent>
          <ReceiveTheCodeDom closeDialog={() => setReceiveTheCodeOpen(false)} />
        </DialogContent>
      </LoginDialog>
    </>
  );
});

export default PhoneEmailLogin;
