"use client";

import LoginDialog from "../Dialog/index";
import { useState, useEffect } from "react";
import { DialogContent, Button } from "@mui/material";
import OldUserLoginPage from "./lodUserLoginPage";
import { MindDataType } from "../../type/index";

interface oldGoLoginDefaultProps {
  scene: number;
  otpType: number;
  userType: string;
  idPrefix?: string;
  identifier: string;
  phoneOrEmail: string;
  stepCode: string | null;
  sessionId: string | null;
  captchaCode: string | null;
  captchaAction?: string | null;
}
interface PropsType {
  isOpen: boolean;
  oldParamsData: any; // 其他数据
  mindData: MindDataType[];
  closeFn?: () => void;
  oldGoPhoneOrEmail: (props: any) => void;
  oldGoLoginDefault: (props?: oldGoLoginDefaultProps) => void; // 关闭弹框的函数
}
const loginDefault = ({ mindData, isOpen, oldParamsData, oldGoLoginDefault, oldGoPhoneOrEmail, closeFn }: PropsType) => {
  const [open, setOpen] = useState(true); // 控制弹窗的显示
  const __closeFN = () => {
    setOpen(false);
    closeFn && closeFn();
  };

  // 打开弹框
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <LoginDialog
        open={open}
        onClose={__closeFN}
        showCloseBtn={true}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "600px",
            minHeight: "580px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent>
          <OldUserLoginPage mindData={mindData} mindFlag={true} oldParamsData={oldParamsData} oldGoLoginDefault={oldGoLoginDefault} oldGoPhoneOrEmail={oldGoPhoneOrEmail} />
        </DialogContent>
      </LoginDialog>
    </div>
  );
};

export default loginDefault;
