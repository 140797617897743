import { parseCookieString } from "@/utils/cookieUtils";
import { isNextServer } from "@/utils/uaParser";

const isServer = isNextServer();

export const fingerPrintCookieKey = '_3AB9D23F7A4B3CSS';

export const getEdiTokenFromCookie = () => {
  let cookieString = "";
  if (isServer) {
    const { cookies } = require("next/headers");
    cookieString = cookies().toString();
  } else {
    cookieString = document.cookie;
  }
  return parseCookieString(cookieString)?.[fingerPrintCookieKey];
};
