export const PUB_KEY =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbNpktS9j5qbO6EB54HIYOkJdkIuyUUwgFBGRDE0+97hZDWEhK2UkzycIAomhd16sixgAULYNGGRknWhfHOMG17TUw8ZDFo7qJRAica2VBuJWd1jZylHq4TJc0xza5ZqdnjtHaF8V8ZN7A3RwnuCaTjhJt5BRBZlbe+Lag2R6RHQIDAQAB';
export const tokenKey = 'jnosTk';
export const refreshTokenKey = 'jnosRtk';
export const jnosAppCategoryKey = 'jnosAppCategory';
export const jnosProductCodeKey = 'jnosProductCode';
/*
  [旧]：图形校验SDK
  [Old]: Graphics Verification SDK
 */
export const GoogleReCAPTCHAEnum = {
  KEY_ID: '6LeBC-QqAAAAAIVQrWJt0xkFHjwIwr8ZngUnXCuQ', 
};

// 登录发送验证码的时候 传入的参数 captchaAction 类型
export const CaptchaActionEnum = {
  SIGNUP: "SIGNUP", // 登录
  FORGET_PASSWORD: "FORGET_PASSWORD", // 忘记密码
  SET_PHONE_EMAIL: "SET_PHONE_EMAIL", // 个人中心 修改手机号
};

// 验证信息：本地存储的key值(Verification information: locally stored key value)
export const StorageKeysMap = {
  verifyParams: "RETAIL_CLOUD_LOGIN_VERIFY_PARAMS",               // 账密登录时二次校验的参数(Parameters for secondary verification when logging in with account password)
  stepCode: "RETAIL_CLOUD_LOGIN_STEP_CODE",                       // 手机号登录方式验证码，实现120s内若收到验证码可重复使用来登录(Mobile phone number login method verification code, if the verification code is received within 120s, it can be reused to log in)
  urlParamsToBind: "RETAIL_CLOUD_LOGIN_URL_PARAMS_TO_BIND_PC",     // 三方账号绑定的页面参数(Page parameters bound to third-party accounts)
  RETURN_URL: "returnUrl"
}
// URL回调参数：兼容设置(URL callback parameters: compatible settings)
export const ReturnurlList = ["ReturnUrl", "returnUrl"];
// 账号验证页面的页面来源标识(The page source identifier of the account verification page)
export const AccountVerifyParams = {
  fromKey: "innerFrom",
  unionLogin: "unionLogin"          // 三方联合登录(Three-party joint login)
}
