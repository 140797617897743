
export const DEF_ADDRESS_BODY = {
  "area": "30000004_30000005_30000006_30000007",
  "postCode": "SW1A 1AA"
}

// -------request start -------

export interface BodyParam {
  area?: string;
  refer?: number;
  client: string;
  clientVersion: string;
  userActionId?: string;
  verticalTag: string;
  omittedDetail?: boolean;
  longitude?: string;
  latitude?: string;
  languageCode?: string;
  countryCode?: string;
  scene?: number;
  businessOrigin?: string;
  cartuuid?: string;
  extendParamMap?: {
    stock: boolean;
    tabId?: string;
  };
  operations?: Operations;
  skuId?: string;
}

export interface GetCartRequest {
  client: string;
  clientVersion: string;
  area: string;
  body: string;
  t: number;
  appid: string;
}

export interface OperationProduct {
  skuId: string;
  num: string;
  itemType: number;
  id: string;
}

export interface OperationPromotion {
  num: string;
  childList: string[];
}

export interface Operations {
  products: OperationProduct[];
  promotions: OperationPromotion[];
}
//  ------- request end -------

// ----- response start ---------
export interface Address {
  addressId: number;
  name: string;
  areaCode: string;
  mobile: string;
  nonEncryptionMobile: string;
  defAddress: boolean;
  fullAddress: any;
  addressDetail: string;
  postCode: any;
  provinceId: number;
  provinceName: string;
  cityId: number;
  cityName: string;
  districtId: number;
  districtName: any;
  townId: number;
  townName: any;
  latitude: number;
  longitude: number;
  tagSource: number;
  tagRet: number;
  userDefinedTag: any;
  countyId: any;
  countyName: any;
  addressLineTwo: any;
  urbanName: any;
  countryId: any;
  countryName: any;
  countryCode: string;
  type: string;
  isDefaultLocal?: boolean;
  postCodeIsValid?: number; // 1符合校验规则；0不符合
  addressDetailIsValid?: number; // 1符合校验规则；0不符合
  unSync?: number; // 1代表：待确认地址类型
}

export interface ListData {
  maxNum: number;
  addressList: Address[];
  unSyncAddressList: Address[]; //联合登录后主站同步的海外地址列表
}

export interface CountyInfo {
  county: string;
  id: number;
}

export interface GroupedCounties {
  countyInfoList: CountyInfo[];
  firstLetter: string;
}

export interface CountyData {
  data: GroupedCounties[];
}
// ----- response end ---------

export interface AddAddress {
  name: string;
  mobile: string;
  defAddress: boolean;
  addressDetail: string;
  addressLineTwo: string;
  postCode: string;
  countyName: string;
  countyId: string | number;
  urbanName: string;
  countryId: number;
  countryName: string;
  countryCode: string;
  addressId?: number;
  source?: number;
}

export interface PostCodeAddress {
  postCode: string;
  provinceId: number;
  cityId: number;
  districtId: number;
  townId: number;
  countryId: number;
  countyName?: string;
  addressId?: number;
  isDefaultLocal?: boolean;
}

export interface GoogleAddress {
  formattedAddress: string;
  placeId: string;
}

