"use client";
import React from "react";
import style from "../../style.module.scss";
import cookieUtils from "@/utils/cookieUtils";
import loginConfig from "@/constants/loginConfig";
import LoginType from "@/common-components-src/const/login";
import UKToast from "@/common-components-src/components/UKToast/index";
import LoadingIcon from "@/common-components-src/components/loadingIcon";
import GreenLock  from "@/common-components-src/assets/icons/greenLock.svg";
import SecurePrivacy  from "@/common-components-src/assets/icons/securePrivacy.svg";
import InfoProtection  from "@/common-components-src/assets/icons/InfoProtection.svg";

import { Button } from "@mui/material";
import { MindDataType } from "../../type/index";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import { sendOTP, judgeNewOrOldUserApi } from "@/common-components-src/api/login/index.api";
import { GoogleReCAPTCHAEnum, CaptchaActionEnum } from "@/common-components-src/js/utils/login/constants";
import { sendClickTrack as oldLoginSendClickTrack, EXPOSURE_RECORD } from "@/tracks/25298";

interface oldGoLoginDefaultProps {
  scene: number;
  otpType: number;
  userType: string;
  idPrefix?: string;
  identifier: string;
  phoneOrEmail: string;
  stepCode: string | null;
  sessionId: string | null;
  captchaCode: string | null;
  captchaAction?: string | null;
}
interface PropsType {
  oldParamsData: any; // 其他数据
  mindFlag?: boolean;
  oldGoLoginDefault: (props?: oldGoLoginDefaultProps) => void; // 关闭弹框的函数
  oldGoPhoneOrEmail: (props: any) => void;
  mindData: MindDataType[];
}
const loginDefault = ({ mindData, mindFlag, oldParamsData, oldGoLoginDefault, oldGoPhoneOrEmail }: PropsType) => {
  const [isLoading, setIsLoading] = React.useState(false);
  // 删除账号
  const removeAccount = () => {
    /** 点击事件上报-移除账号按钮点击 */
    oldLoginSendClickTrack.OldLogin_Remove();
    // 删除 cookie
    cookieUtils.deleteCookie(loginConfig.loginLocalInfo.USER_SUCCESS_ACCOUNT);
    // 关闭弹框
    oldGoLoginDefault && oldGoLoginDefault();
  };
  // 手机号登录线上调用 sessionID
  const getSessionIdSDk = () => {
    setIsLoading(true);
    judgeNewOrOldUserApi({
      otpType: 2,
      idPrefix: oldParamsData?.idPrefix,
      identifier: oldParamsData?.identifier,
    }).then((res:any)=>{
      // 是否白名单 true:不需要生成token，不需要走google验证
      if (res && res.data?.wl) {
          sendOTPCode("");
      } else {
          const w: any = window;
          if (!w.grecaptcha) {
              UKToast.show({icon: "error", content: "Request Google Grecaptcha Enterprise Error"})
              setIsLoading(false);
              return;
          }
          (w)?.grecaptcha.enterprise.ready(async () => {
              const token = await (w)?.grecaptcha.enterprise.execute(GoogleReCAPTCHAEnum.KEY_ID, {action: CaptchaActionEnum.SIGNUP});
              sendOTPCode(token);
          });
      }
    });
  };
  const sendOTPCode = async (token: string | null) => {
    try {
      const params: any = {
        otpType: 2,
        captchaCode: token,
        idPrefix: oldParamsData.idPrefix,
        identifier: oldParamsData.identifier,
        scene: LoginType.VerifyCodeSceneEnum.LOGIN,
        captchaAction: CaptchaActionEnum.SIGNUP,
      };
      const [err, res]: any = await sendOTP(params);
      if (res && res.success) {
        UKToast.show({
          content: "Verification code sent successfully",
          icon: "success",
        });
        oldGoPhoneOrEmail &&
          oldGoPhoneOrEmail({
            ...params,
            userType: "old",
            stepCode: res.data,
            phoneOrEmail: "phone", // 区分手机号还是邮箱
          });
      } else {
        UKToast.show({
          content: res.msg || "Verification code sent fail",
          icon: "error",
        });
      }
    setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  // 跳转到手机/邮箱登录
  const goPhoneOrEmailLogin = () => {
    /** 点击事件上报-登录按钮点击 */
    oldLoginSendClickTrack.OldLogin_SignIn({loginRegisterType: oldParamsData.phoneOrEmail == "phone" ? "1" : "2"})
    setIsLoading(true);
    if (oldParamsData && oldParamsData.phoneOrEmail == "phone") {
      getSessionIdSDk();
    } else {
      oldGoPhoneOrEmail &&
        oldGoPhoneOrEmail({
          ...oldParamsData,
        });
      setIsLoading(false);
    }
  };
  // 协议 PrivacyFn 点击事件
  const agreementPrivacyFn = () => {
    oldLoginSendClickTrack.OldLogin_Privacy();
    location.href = "/help/privacy-policy";
  };
  // 协议 TermsFn 点击事件
  const agreementTermsFn = () => {
    oldLoginSendClickTrack.OldLogin_Term();
    location.href = "/help/terms-conditions";
  };

  return (
    <div className={style.loginDefault}>
      {/* 头部标题 */}
      <div className={style.header}>
        <img src="https://st.joy-sourcing.com/website/other/JoyBuy.png" alt="JoyBuy" />
        <div className={style.header_title}>
          <GreenLock />
          <span>Data encryption</span>
        </div>
      </div>

      {/* 登录表单 */}
      <div className={style.old__btn}>
        {/* 账号信息 */}
        <div className={style.account__info}>
          <span>Last Login</span>
          <div className={style.account__text}>
            {oldParamsData?.phoneOrEmail == "email" && oldParamsData?.identifier}
            {oldParamsData?.phoneOrEmail == "phone" && `${oldParamsData?.idPrefix}${" "}${oldParamsData?.identifier}`}
          </div>
        </div>
        <Button
          variant="contained"
          disableElevation
          disabled={isLoading}
          startIcon={isLoading ? <LoadingIcon /> : null}
          onClick={goPhoneOrEmailLogin}
          exptag={ "exp|" + EXPOSURE_RECORD.OldLogin_SignInExpo }
          data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
          sx={{
            width: "380px",
            height: "48px",
            fontSize: "18px",
            margin: "8px 0",
            backgroundColor: "#CC0C1C", // 自定义背景颜色
            color: "#fff", // 自定义文字颜色
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#990915", // 悬停时的背景颜色
            },
            "&.Mui-disabled": {
              backgroundColor: "#FF9FA7",
              color: "#fff",
            },
          }}
        >
          Sign in
        </Button>
        <Button
          variant="contained"
          disableElevation
          exptag={ "exp|" + EXPOSURE_RECORD.OldLogin_AnotherAccountExpo }
          data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
          onClick={() => {
            /** 点击事件上报-切换账户点击 */
            oldLoginSendClickTrack.OldLogin_AnotherAccount();
            oldGoLoginDefault && oldGoLoginDefault()
          }}
          sx={{
            width: "380px",
            height: "48px",
            fontSize: "18px",
            margin: "8px 0",
            backgroundColor: "#fff", // 自定义背景颜色
            color: "#1a1a1a", // 自定义文字颜色
            textTransform: "none",
            boxShadow: "none",
            border: "1px solid #E0E1E5",
            "&:hover": {
              backgroundColor: "#efefef", // 悬停时的背景颜色
            },
          }}
        >
          Register/Sign in with another account
        </Button>
        {/* 删除账号 */}
        <div 
          exptag={ "exp|" + EXPOSURE_RECORD.OldLogin_RemoveExpo }
          data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
          className={style.remove__account}
          onClick={removeAccount}>
          Remove account
        </div>
      </div>

      {/* footer 底部 footer__page__wrap */}
      <div className={mindFlag ? style.footer__box : style.footer__page__wrap}>
        <div className={style.agreement}>
          <p>By continuing, you agree to our</p>
          <p>
            <span 
              exptag={ "exp|" + EXPOSURE_RECORD.OldLogin_PrivacyExpo }
              data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
              onClick={() => agreementPrivacyFn()}
            >Privacy & Cookie Policy</span>
            and
            <span 
              exptag={ "exp|" + EXPOSURE_RECORD.OldLogin_TermExpo }
              data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
              onClick={() => agreementTermsFn()}>Terms &Condition</span>
          </p>
        </div>
        {mindFlag && (
          <div className={style.mind}
            exptag={ "exp|" + EXPOSURE_RECORD.OldLogin_RegisterExpo }
            data-exptag-config='{"useClick": false, "stay_duration": 0, "repeated":false, "area_rate": 0.01}'
          >
            <div className={style.mind__item}>
              {mindData && mindData.length > 0 && mindData[0].icon ? (
                <img src={mindData && imageUtil.concatImgUrlWithoutForceHttps(mindData[0].icon)} alt="" />
              ) : (
                <SecurePrivacy />
              )}
              <em>{mindData && mindData.length > 0 && mindData[0].serviceTitle}</em>
            </div>
            <div className={style.mind__item}>
              {mindData && mindData.length > 0 && mindData[1].icon ? (
                <img src={mindData && imageUtil.concatImgUrlWithoutForceHttps(mindData[1].icon)} alt="" />
              ) : (
                <InfoProtection />
              )}
              <em>{mindData && mindData.length > 0 ? mindData[1].serviceTitle : ""}</em>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default loginDefault;
