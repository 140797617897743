/* eslint-disable @typescript-eslint/no-explicit-any */
import { jdiColorFetch } from "../colorFetchV2";
import {
  AboutUsMenuRequest,
  AboutUsMenuResponse,
  AboutUsDetailRequest,
  AboutUsDetailResponse,
} from "./help.interface";

// 获取关于我们菜单
export const fetchAboutUsMenu = async (requestData: AboutUsMenuRequest): Promise<AboutUsMenuResponse> => {
  const res = await jdiColorFetch(`user_help_getQuestionList`, {
    method: "post",
    bodyQuery: requestData
  });
  return res;
};

// 获取帮助中心问题详情
export const fetchQuestionDetail = async (requestData: AboutUsDetailRequest): Promise<AboutUsDetailResponse> => {
  const res = await jdiColorFetch(`user_help_getDetail`, {
    method: "post",
    bodyQuery: requestData
  })
  return res;
};

// 查询平台隐私协议历史版本
export const fetchPrivacyPolicyList = async (): Promise<any> => {
  return await jdiColorFetch(`user_settings_getPrivacyPolicyList`, {
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

// 查询隐私协议详情版本
export const fetchPrivacyPolicyDetail = async (requestData: any): Promise<any> => {
  return await jdiColorFetch(`user_settings_getPrivacyPolicy`, {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    bodyQuery: requestData,
  });
};

// 关闭隐私协议变更通知弹窗
export const fetchSettingsConsent = async (): Promise<any> => {
  return await jdiColorFetch(`user_settings_confirmPrivacyPolicy`, {
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

// 查询最新隐私协议最新版本信息
export const fetchVersionInfo = async (): Promise<any> => {
  return await jdiColorFetch(`user_settings_getVersionInfo`, {
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
