import { getClient } from "./loginUtils";
const facebookRedirectUrl = "https://login-m.zode-test.sa/user/bind-transfer";
class ThirdAccountUtils {
  /**
   * @desc Google
   * @param authorizeInfo
   * @param onSuccess
   * @param onFail
   * @param onLoad
   */

  static initGoogleAccount(authorizeInfo, onSuccess, onFail, onLoad) {
    
    if (!window.google) {
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => {
        onLoad();
        this.googleLogin(authorizeInfo, onSuccess, onFail);
      };
      script.onerror = () => {
        onLoad();
        onFail("Failed to initialize the licensed login");
      };
      head.appendChild(script);
    } else {
      onLoad();
      this.googleLogin(authorizeInfo, onSuccess, onFail);
    }
  }
  static googleLogin(authorizeInfo, onSuccess, onFail) {
    try {
      const { authorizeUri, clientId, scope, state, redirectUri } = authorizeInfo || {};

      let GOOGLE = window.google;
      const client = GOOGLE.accounts.oauth2.initCodeClient({
        ux_mode: 'popup',
        ux_config: {
          popup: {
            windowMode: 'newtab',
            openerPolicy: 'same-origin',
          },
        },
        scope: scope,
        state: state || "",
        client_id: clientId,
        response_type: "code",
        // redirect_uri: redirectUri,
        callback: (response) => {
          onSuccess(response?.code);
        },
        error_callback: (error) => {
          console.error("Callback -- Error-三方账号授权登录失败(Third-party account authorization login failed)：", error);
          onFail(error?.message );
        }
      });
      client.requestCode();
    } catch (error) {
      console.error("Try -- Error-三方账号授权登录失败(Third-party account authorization login failed)：", error);
      onFail(/* (error as any)?.type ? (error as any)?.message :  */ "Login licensing failed");
    }
  }
  /**
   * @desc Facebook
   * @param authorizeInfo
   * @param onSuccess
   * @param onFail
   * @param onLoad
   */
  static initFacebookAccount(authorizeInfo, onSuccess, onFail, onLoad) {

    const { clientId, scope } = authorizeInfo || {};
    if (!window.FB) {
      window.fbAsyncInit = function () {
        let FB = window.FB;
        FB.init({
          appId: clientId,
          scope: scope,
          cookie: true,
          xfbml: true,
          version: "v22.0",
        });
      };
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.id = 'facebook-jssdk';
      script.crossOrigin = "anonymous";
      script.async = true;

      script.onload = () => {
        onLoad();
        this.facebookLoginByPopup(authorizeInfo, onSuccess, onFail);
      };
      script.onerror = () => {
        onLoad();
        onFail("Failed to initialize the licensed login");
      };
      head.appendChild(script);
    } else {
      onLoad();
      this.facebookLoginByPopup(authorizeInfo, onSuccess, onFail);
    }
  }
  static facebookLoginByPopup(authorizeInfo, onSuccess, onFail) {
    
    try {
      const { scope } = authorizeInfo || {};
      const FB = window.FB;
      FB.login((loginRes) => {
        // 不管用户有没有登录都会有res
        if (loginRes?.authResponse) {
          const { authResponse } = loginRes || {};
          onSuccess(authResponse?.accessToken || "");
        } else {
          console.error("Error-三方账号授权登录失败(Third-party account authorization login failed)：", loginRes);
          onFail("Login licensing failed");
        }
      },{scope: scope})

    } catch (error) {
      console.error("Error-三方账号授权登录失败(Third-party account authorization login failed)：", error);
      onFail("Failed to initialize the licensed login");
    }

  }

  static facebookLoginByRedirect(authorizeInfo) {
    const client = getClient;
    const redirectUrl = facebookRedirectUrl.facebook;
    window.location.href = `${authorizeInfo.authorizeUri}&redirect_uri=${redirectUrl}`;
  }
  /**
   * @desc Apple
   * @param authorizeInfo
   * @param onSuccess
   * @param onFail
   * @param onLoad
   */
  static initAppleAccount(authorizeInfo, onSuccess, onFail, onLoad) {
    if (!window.AppleID) {
      const head = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
      script.onload = () => {
        onLoad();
        this.appleLogin(authorizeInfo, onSuccess, onFail);
      };
      script.onerror = () => {
        onLoad();
        onFail("Failed to initialize the licensed login");
      };
      head.appendChild(script);
    } else {
      onLoad();
      this.appleLogin(authorizeInfo, onSuccess, onFail);
    }
  }
  static async appleLogin(authorizeInfo, onSuccess, onFail) {
    try {
      const { clientId, scope, state } = authorizeInfo || {};
      let APPLE = window.AppleID;
      const options = {
        // clientId: "WebSignServicesID.com.joybuy.jd",
        // scope: "name email",
        usePopup: true,
        state: state || "", 
        scope: scope || "",
        clientId: clientId || "",
        response_type: "code id_token",
        redirectURI: location?.origin,
      };
      APPLE.auth.init(options);
      const data = await APPLE.auth.signIn();
      console.log("APPLE 登录：", data);
      // onSuccess(data?.authorization?.id_token);
      onSuccess(data?.authorization?.id_token);
    } catch (error) {
      console.error("Error-三方账号授权登录失败(Third-party account authorization login failed)：", error);
      /* if (error && (error as any).error === "popup_closed_by_user") {
        return;
      } */
      onFail("Login licensing failed");
    }
  }
}
export default ThirdAccountUtils;
