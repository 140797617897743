import { isNextServer } from "@/utils/uaParser";

/**
 *
 * 图片工具类
 *
 */
let webpSupported: boolean | undefined;
const imgCDNDomain = process.env.NEXT_PUBLIC_IMAGE_CDN_DOMAIN?.split(",")?.filter((v) => v.trim()) ?? [];

//测试环境图片独立网关上传的图片域名 正式环境需要切换成正式的
const imgColorUrl = "http://test.img.360buyimg.com/pop/"

export const imageUtil = {
  /**
   * Determine whether the domain name of the image in the complete URL is consistent with the specified domain name. If it is inconsistent, maintain a consistent protocol.
   * @param fullImgUrl
   * @param imageHost e.g. http://img.test.com
   * @returns
   */
  matchImageHost(fullImgUrl: string, imageHost: string) {
    const imgKeyAndBiz = getImgDetailsFromAbsoluteImg(fullImgUrl);
    if (imageHost && imgKeyAndBiz && imgKeyAndBiz.imgHost && imgKeyAndBiz.imgKey && imgKeyAndBiz.imgBizDomain) {
      const matched = imageHost.indexOf(imgKeyAndBiz.imgHost) > -1;
      if (matched) {
        return imageHost + "/" + imgKeyAndBiz.imgBizDomain + "/" + imgKeyAndBiz.imgKey;
      }
    }
    return fullImgUrl;
  },
  /**
   * Processing of images in the image system, supporting the incoming image key value and image domain name, selecting an image domain name based on the digital information in the image, and scaling and compressing the image size.
   * @param imgUrlKey The key value of the image, such as jfs/t1/179556/2/1048/51624/60862413E43c4709f/5e3085de6a291d40.png
   * @param domains The domain name of the image, which can be string type or array type
   * @param width width of the image, default 180
   * @param height The height of the image, default 180
   * @param quality The quality of the image, default 70
   * @returns
   */
  concatImgUrlWithoutForceHttps(
    imgUrlKey: string,
    width: number | undefined = undefined,
    height: number | undefined = undefined,
    quality: number = 70,
    domains: string | Array<string> = imgCDNDomain,
  ) {
    if (!imgUrlKey) {
      return "";
    }

    if (!domains) {
      return imgUrlKey;
    }

    if (typeof domains === "string") {
      return this.getImgUrl(domains + imgUrlKey, width, height, quality, false);
    }

    if (domains.length === 0) {
      return imgUrlKey;
    }

    let index = 0;

    // const num = imgUrlKey.replace(/[^\d+]/g, '');
    const fileName = imgUrlKey.substring(imgUrlKey.lastIndexOf("/") + 1, imgUrlKey.indexOf(".")); // Extract part of file name
    const num = fileName.charCodeAt(0) + fileName.charCodeAt(fileName.length - 1);
    if (num) {
      // index = Number(num.slice(num.length -3)) % domains.length;
      index = num % domains.length;
    }
    return this.getImgUrl(domains[index] + imgUrlKey, width, height, quality, false);
  },

  /**
   * Processing of images in the image system, supporting the incoming image key value and image domain name, selecting an image domain name based on the digital information in the image, and scaling and compressing the image size.
   * @param imgUrlKey The key value of the image, such as jfs/t1/179556/2/1048/51624/60862413E43c4709f/5e3085de6a291d40.png
   * @param domains The domain name of the image, which can be string type or array type
   * @param width width of the image, default 180
   * @param height The height of the image, default 180
   * @param quality The quality of the image, default 70
   * @returns
   */
  concatImgUrl(
    imgUrlKey: string,
    width: number | undefined = undefined,
    height: number | undefined = undefined,
    quality: number = 70,
    domains: string | Array<string> = imgCDNDomain,
  ) {
    if (!imgUrlKey) {
      return "";
    }

    if (!domains) {
      return imgUrlKey;
    }

    if (typeof domains === "string") {
      return this.getImgUrl(domains + imgUrlKey, width, height, quality);
    }

    if (domains.length === 0) {
      return imgUrlKey;
    }

    let index = 0;

    const num = imgUrlKey.replace(/[^\d+]/g, "");
    if (num) {
      index = Number(num.slice(num.length - 3)) % domains.length;
    }
    return this.getImgUrl(domains[index] + imgUrlKey, width, height, quality);
  },

  concatImgColorUrl(imgUrlKey: string) {
    if (!imgUrlKey) {
      return "";
    }
    return imgColorUrl + imgUrlKey;
  },
  /**
   * Process the images in the image system and parse the image host, business name, and image key from the absolute address. And increase the image zoom size and compress the image size
   * @param imgUrl The complete image URL address in the image system
   * @param width width of image scaling, default 180
   * @param height The height of the picture with the wind, default 180
   * @param quality Image degradation percentage, default 70
   * @param forceHttps Whether to force https, the default is
   * @returns url returns the complete url address of https protocol, https://domain name/business name/[zoom parameter]image file address[post-processing parameter]
   */

  getImgUrl(
    imgUrl = "",
    width: number | undefined = undefined,
    height: number | undefined = undefined,
    quality: number = 70,
    forceHttps: boolean = true,
  ): string {
    let _imgUrl;
    let _size = "";

    if (!imgUrl) {
      return "";
    }

    if (width && Number(width) > 0 && (height === 0 || (height && Number(height) > 0))) {
      _size = `s${width}x${height}_`;
    }

    if (isAbsoluteURL(imgUrl)) {
      const imgKeyAndBiz = getImgDetailsFromAbsoluteImg(imgUrl);
      if (imgKeyAndBiz && imgKeyAndBiz.imgType !== "gif") {
        _imgUrl = imgKeyAndBiz.imgProtocal + "//" + imgKeyAndBiz.imgHost + "/" + imgKeyAndBiz.imgBizDomain + "/" + _size + imgKeyAndBiz.imgKey;
      } else {
        _imgUrl = imgUrl;
      }

      if (imgKeyAndBiz) {
        // The picture system only performs degradation processing
        if (forceHttps) {
          _imgUrl = replaceAbsoluteURLProtocal(_imgUrl);
        }
        // Degradation is handled uniformly here, and there is no need to pay attention to the business code.
        if (!hasOptmized(_imgUrl)) {
          if (isJpg(_imgUrl)) {
            _imgUrl = `${_imgUrl}!q` + quality;
            // _imgUrl += ".dpg";
          }
          if (isSupportWebp()) {
            _imgUrl += ".webp";
          }
        }
      }

      return _imgUrl;
    } else {
      return imgUrl;
    }
  },

  /**
   * The pictures used for saving will not be degraded or webp added.
   * @param imgUrlKey The complete image URL address in the image system, such as jfs/t1/179556/2/1048/51624/60862413E43c4709f/5e3085de6a291d40.png
   * @param domains The domain name of the image, which can be string type or array type
   * @param width width of image scaling, default 180
   * @param height The height of the picture with the wind, default 180
   * @param forceHttps Whether to force https, the default is
   * @returns url returns the complete url address, for example: https://domain name/business name/[zoom parameter]image file address[post-processing parameter]
   */
  getFullImgUrlForSave(
    imgUrlKey: string,
    width: number | undefined = undefined,
    height: number | undefined = undefined,
    forceHttps: boolean = true,
    domains: string | Array<string> = imgCDNDomain,
  ): string {
    let _imgUrl;
    let _size = "";
    if (!imgUrlKey) {
      return "";
    }

    if (!domains) {
      return imgUrlKey;
    }

    if (domains.length === 0) {
      return imgUrlKey;
    }

    if (typeof domains === "string") {
      _imgUrl = domains + imgUrlKey;
    } else {
      let index = 0;
      const num = imgUrlKey.replace(/[^\d+]/g, "");
      if (num) {
        index = Number(num.slice(num.length - 3)) % domains.length;
      }
      _imgUrl = domains[index] + imgUrlKey;
    }

    if (width && Number(width) > 0 && (height === 0 || (height && Number(height) > 0))) {
      _size = `s${width}x${height}_`;
    }

    const imgKeyAndBiz = getImgDetailsFromAbsoluteImg(_imgUrl);
    if (imgKeyAndBiz && imgKeyAndBiz.imgType !== "gif") {
      _imgUrl = imgKeyAndBiz.imgProtocal + "//" + imgKeyAndBiz.imgHost + "/" + imgKeyAndBiz.imgBizDomain + "/" + _size + imgKeyAndBiz.imgKey;
    }

    if (forceHttps) {
      _imgUrl = replaceAbsoluteURLProtocal(_imgUrl);
    }
    return _imgUrl;
  },

    /**
   * 图片压缩
   * @param file 图片文件对象
   * @param maxSize 将图片压缩到该尺寸以内 单位byte
   * @param limitNum 压缩次数限制
   */
    compressImage(file: any, maxSize: number, limitNum: number = 5){
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
              const img = new Image();
              img.onload = () => {
                  const canvas = document.createElement('canvas');
                  const ctx = canvas.getContext('2d');
                  let width = img.width;
                  let height = img.height;
                  // 先按照一定比例进行初步压缩，这里简单以宽度减半为例，可以根据实际优化调整
                  width /= 2;
                  height /= 2;
                  canvas.width = width;
                  canvas.height = height;
                  ctx && ctx.drawImage(img, 0, 0, width, height);
                  canvas.toBlob((blob) => {
                      if (blob && blob.size > maxSize && limitNum > 1) {
                          // 如果还是超过大小，继续尝试压缩
                          resolve(imageUtil.compressImage(blob, maxSize, limitNum-1));
                      } else {
                          resolve(blob);
                      }
                  }, file.type);
              };
              if(e.target){
                img.src = e.target.result as string;
              }
          };
          reader.readAsDataURL(file);
      });
  }

};
/**
 * Whether to support webp
 * @returns boolean
 */
function isSupportWebp() {
  if (webpSupported !== undefined) {
    return webpSupported;
  }
  if (isNextServer()) {
    webpSupported = true; // 服务端渲染默认都下发webp的图片
    return;
  }
  webpSupported = false;

  try {
    webpSupported = Boolean([].map) && document.createElement("canvas").toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } catch (e) {
    console.log("not support webp");
  }
  return webpSupported;
}

/**
 * Whether it is an absolute URL
 * @param URL
 */
function isAbsoluteURL(URL: string): boolean {
  const str = URL;
  const objExp = new RegExp("^(((https|http)?://)|//)([.0-9a-zA-Z]*.|)", "gi");
  if (objExp.test(str) === true) {
    return true;
  }
  return false;
}

function replaceAbsoluteURLProtocal(url: string) {
  return url.replace(/^(((https|http)?:\/\/)|\/\/)/, "https://");
}

/**
 * Is it a jpg picture?
 * @param url
 */
function isJpg(url: string): boolean {
  const hasPng = /(.png)/.test(url.toLowerCase());
  const hasJpg = /(.jpg|.jpeg)$/.test(url.toLowerCase());
  return hasJpg && !hasPng;
}

function hasOptmized(url: string): boolean {
  const hasDpg = /(.dpg|.webp)/.test(url.toLowerCase());
  const hasQualiity = /(!q[0-9]{2})/.test(url.toLowerCase());
  return hasDpg || hasQualiity;
}
function getImgDetailsFromAbsoluteImg(url: string) {
  const reg = /(http:|https:)?\/\/[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?\/[A-Za-z0-9_]+\/jfs\S+/;
  if (url && reg.test(url)) {
    const regex =
      /(http:|https:)?\/\/([a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?)\/([A-Za-z0-9_]+)\/(jfs\S+\/\S+\.(jpg|jpeg|gif|png|dpg))$/;

    const found = url.match(regex);
    if (found && found.length == 7) {
      return {
        imgProtocal: found[1] || "",
        imgHost: found[2],
        imgBizDomain: found[4],
        imgKey: found[5],
        imgType: found[6],
      };
    }
  }
  return;
}
