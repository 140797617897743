// 埋点曝光露出参数
export const EXPCONFIG_FAST = JSON.stringify({
  useClick: false,
  repeated: false,
  stay_duration: 0.01,
  area_rate: 0.01,
});

export const EXPCONFIG_DELAY = JSON.stringify({
  useClick: false,
  repeated: false,
  stay_duration: 0.3,
  area_rate: 0.5,
});
