"use client";

import loginEnum from "@/common-components-src/const/login";
import LoginDefault from "./components/loginDefault/loginDefault"; // 登录页面
import OldUserLogin from "./components/oldUserLogin/oldUserLogin"; // 老用户登录
import PhoneEmailLogin from "./components/phoneEmailLogin/phoneEmailLogin"; // 手机邮箱登录
import ForgotPassword from "./components/forgetPassword/forgetPassword"; // 忘记密码
import cookieUtils from "@/utils/cookieUtils";
import { getMindData } from "@/common-components-src/api/login/index.api";
import React, { useState, forwardRef, useEffect } from "react";

const defaultMinData = [
  {
    serviceTitle: "Info protection",
    serviceSubTitle: "Info protection",
    icon: "",
  },
  {
    serviceTitle: "Secure privacy",
    serviceSubTitle: "Secure privacy",
    icon: "",
  },
];
const NewLoginDialog = forwardRef(({ open, IndexProps, callback, props, closeFn }, ref) => {
  const [isOpen, setIsOpen] = useState(false); // 控制弹窗的显示
  const [phoneEmailOpen, setPhoneEmailOpen] = useState(false); // 控制手机邮箱登录的显示
  const [paramsData, setParamsData] = useState({});
  const [oldLoginOpen, setOldLoginOpen] = useState(false); // 控制老用户登录的显示
  const [oldParamsData, setOldParamsData] = useState({}); // 老用户页面需要的数据
  const [isForgot, setIsForgot] = useState(false); // 忘记密码
  const [forgotData, setForgotData] = useState({}); // 忘记密码数据
  const [pageFlag, setPageFlag] = useState("loginDefault"); // 打开某个页面 loginDefault oldUserLogin phoneEmailLogin forgotPassword
  const [mindData, setMindData] = useState(defaultMinData); // 心智数据的弹框

  useEffect(() => {
    !IndexProps && setIsOpen(open);
    // setPhoneEmailOpen(open);
    IndexProps && setOldLoginOpen(open);
    if (IndexProps && Object.keys(IndexProps).length > 0) {
      setPageFlag("oldUserLogin"); // 打开老用户登录页面
      setOldParamsData(IndexProps);
    }
  }, [IndexProps]);
  // 登录页面点击 Continue;
  const loginContinue = (props) => {
    setIsOpen(false);
    setParamsData(props && props);
    setPageFlag("phoneEmailLogin");
    setPhoneEmailOpen(true);
  };
  // 跳转到 登陆页面
  const goLoginDefault = (props) => {
    props && setParamsData(props);
    setPageFlag("loginDefault");
    setPhoneEmailOpen(false);
    setIsOpen(true);
  };
  // 老用户跳转到登录弹框
  const oldGoPhoneOrEmail = (props) => {
    props && setParamsData(props);
    setPageFlag("phoneEmailLogin");
    setOldLoginOpen(false);
    setPhoneEmailOpen(true);
  };

  // 忘记密码
  const ForgotPasswordFn = (props) => {
    setPageFlag("forgotPassword");
    setForgotData(props);
    setPhoneEmailOpen(false);
    setIsForgot(true);
  };

  const closeDialog = () => {
    setPhoneEmailOpen(false);
    closeFn && closeFn(true);
  };

  useEffect(() => {
    getMindDataFn()
  }, [])
  // 获取心智数据
  const getMindDataFn = async () => {
    const sessionMindList = cookieUtils.getSessionStorage(loginEnum.Login.MIND_SESSION_STORAGE);
    if (sessionMindList) {
      console.log(JSON.parse(sessionMindList), "sessionMindList");
      const l = JSON.parse(sessionMindList)
      if (l && l.length > 1) {
        setMindData(l);
      }
    } else {
      try {
        const res = await getMindData(loginEnum.MindScopeEnum.SIGN_IN_REGISTER);
        if (res && res.success) {
          if (res.data.length > 1) {
            cookieUtils.setSessionStorage(loginEnum.Login.MIND_SESSION_STORAGE, JSON.stringify(res.data));
            setMindData(res.data);
          }
        }
      } catch (error) {}
    }
  };

  return (
    <>
      {pageFlag == "loginDefault" && <LoginDefault callback={callback} mindData={mindData} props={props} isOpen={isOpen} loginContinue={loginContinue} propsData={paramsData} closeFn={closeDialog} />}
      {pageFlag == "oldUserLogin" && (
        <OldUserLogin
          mindData={mindData}
          isOpen={oldLoginOpen}
          oldParamsData={oldParamsData}
          oldGoLoginDefault={goLoginDefault}
          oldGoPhoneOrEmail={oldGoPhoneOrEmail}
          closeFn={closeDialog}
        />
      )}
      {pageFlag == "phoneEmailLogin" && (
        <PhoneEmailLogin
          props={props}
          callback={callback}
          isOpen={phoneEmailOpen}
          propsData={paramsData}
          goLoginDefault={goLoginDefault}
          ForgotPasswordFn={ForgotPasswordFn}
          closeDialog={closeDialog}
        />
      )}
      {pageFlag == "forgotPassword" && <ForgotPassword isOpen={isForgot} propsData={forgotData} goLoginDefault={loginContinue} closeFn={closeDialog} />}
    </>
  );
});

export default NewLoginDialog;
