/*
 * @Author: huguantao1@jd.com
 * @Date: 2025-03-14 16:50:38
 * @LastEditors: huguantao1@jd.com
 * @LastEditTime: 2025-03-18 18:38:47
 * @FilePath: /intl-pc-jdi-website-ssr/src/utils/eventTrackingUtils.tsx
 * @Description: default
 */

import cookieUtil from "@/utils/cookieUtils";
interface PVParam {
  page_id: string;
  page_param?: any;
}

interface CLParam {
  event_id: string;
  page_id?: string;
  json_param?: any;
  event_param?: any;
}

interface ExpParam {
  event_id: string;
  json_param?: any;
  event_param?: any;
}

export const eventTrackingUtils = {
    // pageId = ''
    // -----------浏览上报-------------
    submitPV({ page_id, page_param }: PVParam) {
      try {
        var pv = new MPing.inputs.PV(page_id);
        // 从sessionStorage 中取上一个页面的pageId
        const lastPageId = cookieUtil.getSessionStorage("lastPageId") || "";
        // 获取之前的记录
        const history = cookieUtil.getSessionStorage("pageHistory") ? JSON.parse(cookieUtil.getSessionStorage("pageHistory")) : [];
        // 获取页面的referrer
        const referrer = document.referrer;
        // 判断上一个页面是否为站内页面，如果为站外页面并且有referrer则上传referrer，并且删除sessionStorage，否则上传page_id
        const outerUrl = referrer && new URL(referrer).host !== location.host;
        const currentRecord = outerUrl ? referrer : lastPageId;
  
        // from_page: 上一个页面id. SDK自动收集且为URL而不是pageid，前端无法修改，因此增加字段 refPage 用于给数据那边获取上一跳的pageid
        if (page_param) {
          pv.page_param = JSON.stringify({ ...page_param, refPage: currentRecord || "", preRef: history[1] || "" });
        } else {
          pv.page_param = JSON.stringify({ refPage: currentRecord || "", preRef: history[1] || "" });
        }
  
        // 将新记录插入到数组开头
        history.unshift(currentRecord);
        // 限制数组长度为 2
        if (history.length > 5) {
          history.pop(); // 移除最后的记录
        }
        // 存储lastPageId
        cookieUtil.setSessionStorage("lastPageId", page_id);
        // 存储更新后的历史记录
        cookieUtil.setSessionStorage("pageHistory", JSON.stringify(history));
        var mping = new MPing(); // 构造上报实例
        mping.send(pv);
      } catch (e) {}
    },

    // getPageId () {
    //     return this.pageId
    // }

    //------------点击上报---------------
    submitCL({ event_id, page_id, json_param, event_param }: CLParam) {
        try {
            var click = new MPing.inputs.Click(event_id, {    // 必选参数，事件id
            });
            // 不再需要传入page_id, sendPV后，子午线会自动关联
            // 为了兼容老版本，保留入参page_id,不使用即可
            click.event_param = JSON.stringify(event_param);
            click.json_param = JSON.stringify(json_param);
            (new MPing()).send(click);                           // 构造上报实例，上报点击事件
        } catch (e) { }
    },

  submitExp({ event_id, json_param, event_param }: ExpParam) {
    try {
      var exposureId = event_id; // 必选参数，事件id
      var exposure = new MPing.inputs.Exposure(exposureId); // 构造点击上报对象
      // exposure.event_param = JSON.stringify(event_param);
      exposure.json_param = JSON.stringify(json_param); // 设置json格式事件参数，必须是合法的json字符串
      new MPing().send(exposure); // 构造上报实例，上报点击事件
    } catch (e) {}
  },
};
