"use client";
import LoginType from "../../../const/login";
import cookieUtil from "@/utils/cookieUtils";
import { judgeNewOrOldUserApi } from "@/common-components-src/api/login/index.api";
import { CaptchaActionEnum } from "@/common-components-src/js/utils/login/constants";

// 获取当前账号新用户还是老用户
export const judgeNewOrOldUserFn = async (params: {
  type: string;
  identifier: string | number;
  PhoneSign?: string | number;
}) => {
  const res: any = await judgeNewOrOldUserApi({
    // 2 代表手机号, 3 代表邮箱
    otpType: params.type === "phone" ? 2 : 3,
    identifier: params.identifier,
    idPrefix: params.type === "phone" ? "+" + params.PhoneSign : "",
  });
  let userType = "";
  // exist true 表示当前账号是新用户，存在表示老用户
  if (res && res?.data?.exist) {
    userType = "old";
  } else {
    userType = "new";
  }
  // wl 表示是否是白名单, 白名单: true 不进行 Google 验证; 非白名单: false 进行 Google 验证
  return { userType, wl: res?.data?.wl };
};

// 登录注册账号输入的时候邮箱校验；
export const validateEmail = (email: string) => {
  // return specialCharsRegex.test(input);
  // 检查 @ 符号之前的部分长度
  const localPart = email.split("@")[0];
  if (localPart.length < 2 || localPart.length > 64) {
    return false;
  }
  // 检查整个电子邮件地址的长度
  if (email.length > 254) {
    return false;
  }

  // 如果所有检查都通过，则电子邮件地址有效
  return true;
};

// 提取国际手机号 + 后的数字；
export const extractPhoneNumber = (phone: string) => {
  const regex = /\+(\d+)/;
  const match = phone.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};

interface EmailPropsType {
  appId?: string | number;
  scene?: number;
  email?: string;
  otpType?: number;
  password?: string | null;
  userType?: string;
  sessionId?: string;
  identifier?: string;
  loginType?: number;
  phoneOrEmail?: string;
  captchaCode: string | null;
  captchaAction?: string | null;
}

// 邮箱登录参数提取
export const extractEmailParams = async (props: EmailPropsType) => {
  let data: EmailPropsType;
  if (props.userType === "new") {
    data = {
      email: props.identifier,
      password: props.password,
      appId: LoginType.Login.LOGIN_APPID,
      captchaCode: props?.sessionId || cookieUtil.getSessionStorage("sessionId"),
      captchaAction: CaptchaActionEnum.SIGNUP,
    };
  } else {
    data = {
      identifier: props.identifier,
      password: props.password,
      loginType: LoginType.LoginTypeEnum.EMAIL,
      otpType: LoginType.LoginTypeEnum.EMAIL,
      captchaCode: props?.sessionId || cookieUtil.getSessionStorage("sessionId"),
      captchaAction: CaptchaActionEnum.SIGNUP,
    };
  }
  return data;
};

// 手机号登录存储手机号 加 验证码倒计时
export const storePhoneNumberAndCodeTime = (phoneNumber: string | number, codeTime: number | string,  idPrefix: string | undefined) => {
  const data = `${phoneNumber}|${codeTime}|${idPrefix}`;
  cookieUtil.setSessionStorage('phoneAndCodeTime', data);
};

// 解析手机号 加 验证码倒计时
export const parsePhoneNumberAndCodeTime = () => {
  const data = cookieUtil.getSessionStorage('phoneAndCodeTime');
  if (data) {
    const [phoneNumber, codeTime, idPrefix] = data.split('|');
    return { phoneNumber, codeTime, idPrefix };
  }
  return { phoneNumber: '', codeTime: '', idPrefix: "" };
};

// 判断是否在 1 分钟内
export const isWithinOneMinute = (timestamp: number) => {
  const currentTime = new Date().getTime() / 1000;
  const timeDifference = currentTime - timestamp;
  const oneMinuteInMilliseconds = 60;
  return timeDifference < oneMinuteInMilliseconds;
}

// 邮箱列表
export const emailList: Array<string> = [
  "@gmail.com", 
  "@hotmail.com", 
  "@yahoo.com", 
  "@yahoo.co.uk", 
  "@icloud.com", 
  "@msn.com", 
  "@live.com", 
  "@qq.com",
  "@btinternet.com", 
  "@virginmedia.com", 
  "@btinternet.com", 
  "@live.co.uk", 
  "@sky.com", 
  "@talktalk.co.uk", 
  "@blueyonder.co.uk", 
  "@ntlworld.com", 
  "@me.com"
]

// 获取url 中 ext_ 参数
export function splitAndProcess() {
  const url = window.location.href.split("?")[1];
  if (!url) {
    return;
  }
  const data = url.split('&');
  if(data && data.length == 0) {
    return;
  }
  const list = data.map((part: any) => {
    const [key, value] = part.split('=');
    return { key, value};
  });
  let obj: any = {};
  list && list.length > 0 && list.forEach((item: any) => {
    if (item.key.startsWith('ext_')) {
      obj[item.key.replace(/^ext_/, '')] = item.value;
    }
  });
  return obj;
}

  // 格式化手机号码
  export const formatNumber = (v: any) => {
    if (!isNaN(v) && v.length > 0 && v.length < 14) {
      let formattedNumber = '';
      if (v.startsWith('0')) {
        // 以 0 开头，格式化为 07 333 666666;
        if (v.length == 3) formattedNumber = `${v.slice(0, 2)} ${v.slice(2, 3)}`;
        else if (v.length == 4) formattedNumber = `${v.slice(0, 2)} ${v.slice(2, 4)}`;
        else if (v.length == 5) formattedNumber = `${v.slice(0, 2)} ${v.slice(2, 5)}`;
        else if (v.length > 5) formattedNumber = `${v.slice(0, 2)} ${v.slice(2, 5)} ${v.slice(5, 11)}`;
        else formattedNumber = v;
      } else {
        // 其他数字输入
        if (v.length == 10) formattedNumber = `${v.slice(0, 4)} ${v.slice(4, 10)}`;
        else if (v.length == 11) formattedNumber = `${v.slice(0, 2)} ${v.slice(2, 5)} ${v.slice(5, 11)}`;
        else if (v.length > 4) formattedNumber = `${v.slice(0, 4)} ${v.slice(4)}`;
        else formattedNumber = v;
      }
      // setInputVal(formattedNumber.replace(/\s+/g, ''));
      // setFormatNumber(formattedNumber);
    } else {
      // setInputVal(v);
      // setFormatNumber(v);
    }
  };
