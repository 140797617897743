import { logger } from "@/utils/logger";
import { isNextClient, isNextServer } from "@/utils/uaParser";
import { getSSrAddressBody } from "@/utils/addressUtils";
import JDILogin from "@/common-components-src/js/newlogin/index.newlogin";
import fetchToCurl from "fetch-to-curl";
import { getEdiTokenFromCookie } from "@/utils/fingerprint";
import isInWhiteList from "@/constants/attributionWhiteList";
import cookieUtil from "@/utils/cookieUtils";

type Json = string | number | boolean | null | { [property: string]: Json } | Json[] | any;

interface RequestInitWithBodyQuery extends RequestInit {
  bodyQuery?: Json;
}

const isBrowser = isNextClient();
const isServer = isNextServer();

const COLOR_URL = (isBrowser ? process.env.NEXT_PUBLIC_COLOR_URL : process.env.COLOR_URL) as string;
const BASE_URL = isBrowser ? window.location.origin : undefined;

const timeout = 10000;

const reqIntercept_SetTimeout = (options: RequestInit = {}) => {
  options = Object.assign({ signal: AbortSignal.timeout(timeout) }, options);
  return { options };
};

const getUserRealIp = () => {
  const { headers } = require("next/headers");
  const FALLBACK_IP_ADDRESS = "0.0.0.0";
  const forwardedFor = headers().get("J-Forwarded-For");

  if (forwardedFor) {
    return forwardedFor.split(",")[0] ?? FALLBACK_IP_ADDRESS;
  }

  return headers().get("x-real-ip") ?? FALLBACK_IP_ADDRESS;
};

const reqIntercept_AddAttributionParams = (functionId: string, options: RequestInitWithBodyQuery = {}) => {
  if (isInWhiteList(functionId)) {
    // 获取用户归因
    let attribution = "";
    if (isServer) {
      const { cookies } = require("next/headers");
      attribution = cookies().get("_attribution");
    } else {
      attribution = cookieUtil.getCookie("_attribution");
    }
    if (attribution) {
      if (options.bodyQuery) {
        options.bodyQuery.launchAttribution = attribution;
      } else {
        options.bodyQuery = { launchAttribution: attribution };
      }
    }
  }
  return { options };
};

const reqIntercept_AddCommonParams = (functionId: string, options: RequestInitWithBodyQuery = {}) => {
  let cookieString = "";
  if (isServer) {
    const { cookies } = require("next/headers");
    cookieString = cookies().toString();
  } else {
    cookieString = document?.cookie;
  }
  const addressBody = getSSrAddressBody(cookieString);

  const defaultUrlSearchParams = new URLSearchParams({
    functionId,
    appid: "joybuyPC",
    loginType: "30",
    client: "pc",
    clientVersion: "1.0.0",
    t: String(new Date().getTime()),
    "x-api-eid-token": getEdiTokenFromCookie(),
  });

  let bodyQuerySearchParams = new URLSearchParams({});
  if (options.bodyQuery) {
    Object.assign(options.bodyQuery as any, addressBody);
    bodyQuerySearchParams = new URLSearchParams({ body: JSON.stringify(options.bodyQuery) });
  } else {
    // options.bodyQuery = addressBody;
  }
  const urlObj = new URL(COLOR_URL, BASE_URL);
  urlObj.search = new URLSearchParams({
    ...Object.fromEntries(defaultUrlSearchParams),
    ...Object.fromEntries(bodyQuerySearchParams),
  }).toString();
  return { url: urlObj.toString(), options };
};

const reqIntercept_AddHeaders = async (url: string, options: RequestInit = {}) => {
  const headers = new Headers(options.headers);
  headers.append("x-api-platform", "pc");
  headers.append("x-requested-with", "XMLHttpRequest");
  headers.append("origin", "http://www.joybuy.com");

  if (isServer) {
    // 获取用户cookie
    const { cookies, headers: nextHeaders } = require("next/headers");

    const cookieStore = cookies();

    const cookieString = cookieStore.toString();

    headers.append("Cookie", cookieString);
    // 获取特性环境标识
    const swimlane = nextHeaders().get("swimlane");

    headers.append("User-Agent", nextHeaders().get("User-Agent"));
    if (swimlane) {
      headers.append("swimlane", swimlane);
    }
    // 获取用户真实ip
    const userIp = getUserRealIp();
    headers.append("X-Proxy-Client-Ip", userIp);
  }
  options.headers = headers;
  options.credentials = "include";

  return { url, options };
};

const resIntercept_authorizationCheck = async (res: any) => {
  // const responseData = await res.json();
  // 此处暂时不做处理，因为未登陆态，前端以弹窗形式呈现登录框，而非打开登录页。
  // tips: 业务401 异常处理
  // console.log("resIntercept_authorizationCheck", res);
  if (res.code === "401") {
    if (isBrowser) {
      JDILogin.getInstance().openLoginDialog(() => {});
    }
  }
};

const resIntercept_errorHandler = async (res: Response, url: string, options: RequestInit = {}) => {
  if (res.status !== 200) {
    logger.error({ url, status: res.status, method: options.method }, "API请求异常");
  }
};

// 参考 https://stackoverflow.com/questions/46946380/fetch-api-request-timeout 实现
export const jdiColorFetch = async (functionId: string, options: RequestInitWithBodyQuery = {}) => {
  const requestId = Math.random().toString(36).slice(2);
  let url = COLOR_URL;
  // 新增归因参数
  ({ options } = reqIntercept_AddAttributionParams(functionId, options));
  // 新增超时时间
  ({ options } = reqIntercept_SetTimeout(options));
  // 新增通用参数和bodyQuery里的业务参数
  ({ url, options } = reqIntercept_AddCommonParams(functionId, options));
  // 新增通用header
  ({ url, options } = await reqIntercept_AddHeaders(url, options));
  logger.info({
    requestId,
    baseURL: COLOR_URL,
    url,
    functionId,
    type: "request",
    requestOption: options,
    header: Object.fromEntries((options.headers as Headers).entries()),
  });

  try {
    // 发起请求
    const response = await fetch(url, options);
    if (process.env.APP_ENV !== "production") {
      logger.info({ requestId, curlCommand: fetchToCurl(url, options) });
    }

    // const responseCloned = response.clone();
    const responseData = await response.json();
    // 401鉴权处理
    resIntercept_authorizationCheck(responseData);
    // 接口报错打印日志
    resIntercept_errorHandler(response, url, options);

    logger.info({ requestId, baseURL: COLOR_URL, functionId, type: "response", data: JSON.stringify(responseData) });

    return responseData;
  } catch (error) {
    logger.error({ url, method: options.method, error }, "API Exception");
    return { error: error };
  }
};
