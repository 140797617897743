"use client";

import LoginDialog from "../Dialog/index";
import { useState, useEffect } from "react";
import { DialogContent } from "@mui/material";
import LoginDefaultPage from "./loginDefaultPage";
import { MindDataType } from "../../type/index";

interface PropsType {
  isOpen: boolean;
  propsData?: any; // 其他数据
  props: { isRefresh?: boolean; isForgotPassword?: boolean; isReturnUrl?: boolean }; // 登录时传入参数 控制是否强制刷新页面等
  mindData: MindDataType[];
  closeFn?: () => void;
  callback: (props?: any) => void; // 登录成功后的回调函数
  loginContinue?: (props?: any) => void; // 关闭弹框的函数
}
const loginDefault = ({ isOpen, propsData, props, loginContinue, closeFn, callback, mindData }: PropsType) => {
  const [open, setOpen] = useState(true); // 控制弹窗的显示
  const closeFN = () => {
    setOpen(false);
    closeFn && closeFn();
  };
  // 打开弹框
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      <LoginDialog
        open={open}
        onClose={closeFN}
        showCloseBtn={true}
        sx={{
          "& .MuiDialog-paper": {
            width: "600px", // 设置自定义宽度
            // height: "580px",
            minHeight: "580px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent>
          <LoginDefaultPage isDialog={true} callback={callback} mindData={mindData} props={props} mindFlag={true} propsData={propsData} loginContinue={loginContinue} closeFN={closeFN} />
        </DialogContent>
      </LoginDialog>
    </div>
  );
};

export default loginDefault;
