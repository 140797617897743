"use client";

import LoginDialog from "../Dialog/index";
import style from "../../style.module.scss";
import cookieUtils from "@/utils/cookieUtils";
import loginConfig from "@/constants/loginConfig";
import LoginType from "@/common-components-src/const/login";
import Edit  from "@/common-components-src/assets/icons/Edit.svg";
import Closed  from "@/common-components-src/assets/icons/Closed.svg";
import RedClose  from "@/common-components-src/assets/icons/redClose.svg";
import UKToast from "@/common-components-src/components/UKToast/index";

import { useState, useEffect } from "react";
import { FormControlInputStyle } from "../../style/style";
import { CaptchaActionEnum } from "@/common-components-src/js/utils/login/constants";
import { sendOTP, checkEmailCodeApi } from "@/common-components-src/api/login/index.api";
import { DialogContent, Button, IconButton, FormControl, InputAdornment, InputLabel, OutlinedInput, FormHelperText } from "@mui/material";

import { sendClickTrack as emailPasswordSendClickTrack, EXPOSURE_RECORD as EMAIL_PASSWORD_EXPOSURE_RECORD } from "@/tracks/25290";

interface PropsType {
  isOpen: boolean;
  propsData?: any; // 其他数据
  closeFn?: () => void;
  goLoginDefault?: (props?: any) => void; // 关闭弹框的函数
}
const ForgetPassword = ({ isOpen, propsData, goLoginDefault, closeFn }: PropsType) => {
  const [open, setOpen] = useState(true); // 控制弹窗的显示
  const [inputValue, setInputValue] = useState<string | null>(""); // 输入框的值
  const [helperTextError, setHelperTextError] = useState(""); // 输入框错误提示
  const [buttonDisabled, setButtonDisabled] = useState(true); // 判断按钮是是否禁用
  const [forgetCode, setForgetCode] = useState<boolean>(true); // 是否显示验证码页面
  // 打开弹框
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  useEffect(() => {
    const regex = /^[a-zA-Z0-9]{6}$/;
    setHelperTextError("");
    setButtonDisabled(!regex.test(inputValue as string));
  }, [inputValue]);
  const __close = () => {
    setOpen(false);
    closeFn && closeFn();
  };
  // 发送验证码
  const Submit = async () => {
    /** 点击事件上报-邮箱验证弹层-提交按钮 */
    emailPasswordSendClickTrack.EmailPassword_EmailVerify_Submit();
    setInputValue("");
    setHelperTextError("");
    sendOTPCode("");
  };

  const sendOTPCode = async (token: string | null) => {
    const params: any = {
      otpType: 3,
      captchaCode: token,
      identifier: propsData.identifier,
      captchaAction: CaptchaActionEnum.FORGET_PASSWORD,
      scene: LoginType.VerifyCodeSceneEnum.RETRIEVE_PASSWORD,
    };
    const [err, res]: any = await sendOTP(params);
    if (res && res.success) {
      UKToast.show({
        content: "Verification code sent successfully",
        icon: "success",
      });
      cookieUtils.setSessionStorage("_stepCode", res.data);
      setHelperTextError("");
      setForgetCode(false);
    } else {
      UKToast.show({
        content: res.msg,
        icon: "error",
      });
      setHelperTextError(res.msg);
    }
  }

  // 校验当前验证码
  const codeSubmit = async () => {
    /** 点击事件上报-挽留弹窗-继续注册 */
    emailPasswordSendClickTrack.EmailPassword_RetainExpo_Continue({isNew: propsData?.userType === "new" ? "1" : "0"})
    try {
      const [err, res]: any = await checkEmailCodeApi({
        otpType: 3,
        optVerifyCode: inputValue as string,
        email: propsData.identifier,
        stepCode: cookieUtils.getSessionStorage("_stepCode"), // 获取 发送邮箱验证码返回的 步骤码
        scene: LoginType.VerifyCodeSceneEnum.RETRIEVE_PASSWORD,
      });
      if (res && res.success) {
        cookieUtils.clearSessionStorage("_stepCode"); // 删除 邮箱验证码返回的 步骤码
        cookieUtils.setCookie("_lsa", propsData.identifier, 15, loginConfig.POSTFIX_DOMAIN);
        const path = `/forgot-password?showRememberMe=true&prePopuLatedStepCode=${res.data}`;
        window.location.href = path;
      } else {
        // setHelperTextError("The code is incorrect, please try again");
        UKToast.show({
          icon: "error",
          content: res.msg,
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  //   清除输入框 
  const closeCode = () => {
    setInputValue("");
    setHelperTextError("");
  };
  // 返回按钮逻辑
  const onReturnFn = () => {
    if (!forgetCode) {
      setForgetCode(true);
    } else {
      goLoginDefault && goLoginDefault(propsData);
    }
  };
  return (
    <LoginDialog
      open={open}
      onClose={__close}
      showCloseBtn={true}
      showReturnBtn={true}
      onReturn={onReturnFn}
      sx={{
        "& .MuiDialog-paper": {
          width: "600px", // 设置自定义宽度
          minHeight: "580px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent>
        <div className={style.forget__wrap}
          /** 邮箱验证弹层曝光 */
          exptag={"exp|" +  EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_EmailVerifyExpo}
          data-exptag-config='{"useClick": false, "stay_duration": 0 "repeated":false, "area_rate": 0.01}'
        >
          {/* 标题盒子 */}
          <div className={style.title__box}
              exptag={"exp|" +  EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_EmailVerifyExpo}
              data-exptag-config='{"us[eClick": false, "stay_duration": 0 "repeated":false, "area_rate": 0.01}'
          >
            <div className={style.title}>Forgot password</div>
            {forgetCode ? (
              <div className={style.title__text}>Confirm your email and we will send a reset verification code.</div>
            ) : (
              <div className={style.title__text}>
                A verification code to reset your password has been sent to <span>{propsData.identifier}</span>
              </div>
            )}
          </div>
          <div className={style.send__box}>
            {/* 发送验证码盒子 */}
            {forgetCode && (
              <>
                <FormControl sx={{ ...FormControlInputStyle }} variant="outlined" margin="normal">
                  <InputLabel htmlFor="outlined-adornment-email">Email</InputLabel>
                  <OutlinedInput
                    type="text"
                    disabled
                    defaultValue={propsData.identifier}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => goLoginDefault && goLoginDefault(propsData)}>
                          <Edit />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {/* {!!error && <FormHelperText>{error}</FormHelperText>} */}
                </FormControl>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={Submit}
                  exptag={"exp|" +  EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_EmailVerify_SubmitExpo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0 "repeated":false, "area_rate": 0.01}'
                  sx={{
                    width: "100%",
                    height: "48px",
                    fontSize: "20px",
                    margin: "25px 0",
                    backgroundColor: "#CC0C1C", // 自定义背景颜色
                    color: "#fff", // 自定义文字颜色
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#990915", // 悬停时的背景颜色
                    },
                  }}
                >
                  Submit
                </Button>
              </>
            )}
            {/* 验证验证码盒子 */}
            {!forgetCode && (
              <>
                <FormControl error={!!helperTextError} sx={{ ...FormControlInputStyle }} variant="outlined" margin="normal">
                  <InputLabel htmlFor="outlined-adornment-email">Verification code</InputLabel>
                  <OutlinedInput
                    type="text"
                    value={inputValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const newValue = event.target.value.replace(/[^0-9]/g, "");
                      let value = event.target.value;
                      if (value !== newValue) {
                        value = newValue;
                      } else {
                        const codeNum = value;
                        if (codeNum.length > 6) {
                          value = value.slice(0, 6);
                        }
                        setInputValue(value);
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={closeCode}>
                          <Closed />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {!!helperTextError && (
                    <FormHelperText>
                      <div className={style.errorText}>
                        <RedClose />
                        {helperTextError}
                      </div>
                    </FormHelperText>
                  )}
                </FormControl>
                <div className={style.list}>
                  <p>
                    <span>· </span>
                    <span className={style.list__item__text}>Email may experience a few minute delay.</span>
                  </p>
                  <p>
                    <span>· </span>
                    <span className={style.list__item__text}>If you have not received an email, please check your junk and spam folders.</span>
                  </p>
                  <p>
                    <span>· </span>
                    <span className={style.list__item__text}>
                      If you still don’t receive the email after requesting a password reset, wait 24 hours and{" "}
                      <span onClick={()=> {
                          /** 点击事件上报-邮箱验证弹层-再次尝试 */
                          emailPasswordSendClickTrack.EmailPassword_EmailVerify_TryAgain();
                          Submit()
                        }} className={style.try_again}>
                        try again
                      </span>
                      .
                    </span>
                  </p>
                </div>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={codeSubmit}
                  disabled={buttonDisabled}
                  /** 邮箱验证弹层-再次尝试曝光 */
                  exptag={"exp|" +  EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_EmailVerify_TryAgainExpo}
                  data-exptag-config='{"useClick": false, "stay_duration": 0 "repeated":false, "area_rate": 0.01}'
                  sx={{
                    width: "100%",
                    height: "48px",
                    fontSize: "20px",
                    margin: "25px 0",
                    backgroundColor: "#CC0C1C", // 自定义背景颜色
                    color: "#fff", // 自定义文字颜色
                    textTransform: "none",
                    boxShadow: "none",
                    "&:hover": {
                      backgroundColor: "#990915", // 悬停时的背景颜色
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#FF9FA7",
                      color: "#fff",
                    },
                  }}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </LoginDialog>
  );
};

export default ForgetPassword;
