// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25290
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25290
import { eventTrackingUtils } from "@/utils/eventTrackingUtils";
interface IExpoTrackParamsRecord {
  /** 注册按钮曝光 */
  EmailPassword_RegisterExpo: undefined;
  /** 登录按钮曝光 */
  EmailPassword_SignInExpo: undefined;
  /** 忘记密码曝光 */
  EmailPassword_ForgetExpo: undefined;
  /** 邮箱验证弹层曝光 */
  EmailPassword_EmailVerifyExpo: undefined;
  /** 邮箱验证弹层-提交按钮曝光 */
  EmailPassword_EmailVerify_SubmitExpo: undefined;
  /** 邮箱验证弹层-再次尝试曝光 */
  EmailPassword_EmailVerify_TryAgainExpo: undefined;
  /** 挽留弹窗曝光 */
  EmailPassword_RetainExpo: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord, string>> = Object.freeze({
  /** 注册按钮曝光 */
  EmailPassword_RegisterExpo: "EmailPassword_RegisterExpo",
  /** 登录按钮曝光 */
  EmailPassword_SignInExpo: "EmailPassword_SignInExpo",
  /** 忘记密码曝光 */
  EmailPassword_ForgetExpo: "EmailPassword_ForgetExpo",
  /** 邮箱验证弹层曝光 */
  EmailPassword_EmailVerifyExpo: "EmailPassword_EmailVerifyExpo",
  /** 邮箱验证弹层-提交按钮曝光 */
  EmailPassword_EmailVerify_SubmitExpo: "EmailPassword_EmailVerify_SubmitExpo",
  /** 邮箱验证弹层-再次尝试曝光 */
  EmailPassword_EmailVerify_TryAgainExpo: "EmailPassword_EmailVerify_TryAgainExpo",
  /** 挽留弹窗曝光 */
  EmailPassword_RetainExpo: "EmailPassword_RetainExpo",
});

interface IKeyActionTrackParamsRecord {
  /** 用户登录结果返回 */
  EmailPassword_LoginResult: {
    /** 是否成功  */
    is_success: string;
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
}

const sendKeyActionTrack = {
  /** 关键事件上报-用户登录结果返回 */
  EmailPassword_LoginResult: (json_param: IKeyActionTrackParamsRecord["EmailPassword_LoginResult"]) => {
    eventTrackingUtils.submitExp({ event_id: "EmailPassword_LoginResult", json_param });
  },
};

interface IClickTrackParamsRecord {
  /** 注册按钮 */
  EmailPassword_Register: undefined;
  /** 登录按钮 */
  EmailPassword_SignIn: undefined;
  /** 忘记密码选项 */
  EmailPassword_Forget: undefined;
  /** 邮箱验证弹层-提交按钮 */
  EmailPassword_EmailVerify_Submit: undefined;
  /** 邮箱验证弹层-再次尝试 */
  EmailPassword_EmailVerify_TryAgain: undefined;
  /** 挽留弹窗-继续注册 */
  EmailPassword_RetainExpo_Continue: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 挽留弹窗-离开 */
  EmailPassword_RetainExpo_Leave: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
}

const sendClickTrack = {
  /** 点击事件上报-注册按钮 */
  EmailPassword_Register: () => {
    eventTrackingUtils.submitCL({ event_id: "EmailPassword_Register" });
  },
  /** 点击事件上报-登录按钮 */
  EmailPassword_SignIn: () => {
    eventTrackingUtils.submitCL({ event_id: "EmailPassword_SignIn" });
  },
  /** 点击事件上报-忘记密码选项 */
  EmailPassword_Forget: () => {
    eventTrackingUtils.submitCL({ event_id: "EmailPassword_Forget" });
  },
  /** 点击事件上报-邮箱验证弹层-提交按钮 */
  EmailPassword_EmailVerify_Submit: () => {
    eventTrackingUtils.submitCL({ event_id: "EmailPassword_EmailVerify_Submit" });
  },
  /** 点击事件上报-邮箱验证弹层-再次尝试 */
  EmailPassword_EmailVerify_TryAgain: () => {
    eventTrackingUtils.submitCL({ event_id: "EmailPassword_EmailVerify_TryAgain" });
  },
  /** 点击事件上报-挽留弹窗-继续注册 */
  EmailPassword_RetainExpo_Continue: (json_param: IClickTrackParamsRecord["EmailPassword_RetainExpo_Continue"]) => {
    eventTrackingUtils.submitCL({ event_id: "EmailPassword_RetainExpo_Continue", json_param });
  },
  /** 点击事件上报-挽留弹窗-离开 */
  EmailPassword_RetainExpo_Leave: (json_param: IClickTrackParamsRecord["EmailPassword_RetainExpo_Leave"]) => {
    eventTrackingUtils.submitCL({ event_id: "EmailPassword_RetainExpo_Leave", json_param });
  },
};

export { EXPOSURE_RECORD, sendClickTrack, sendKeyActionTrack };
export type { IExpoTrackParamsRecord };
