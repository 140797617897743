import style from "../../style.module.scss";
import LoginDialog from "../Dialog/index";

import { useState, useEffect } from "react";
import { DialogContent, Button } from "@mui/material";
import { sendClickTrack as msgCodeSendClickTrack, EXPOSURE_RECORD as MSG_CODE_EXPOSURE_RECORD } from "@/tracks/25277"
import { sendClickTrack as emailPasswordSendClickTrack, EXPOSURE_RECORD as EMAIL_PASSWORD_EXPOSURE_RECORD } from "@/tracks/25290";

interface PropsType {
  isOpen: boolean;
  mindData?: any;
  propsData?: any;
  leaveNow: () => void;
  onlyClose?: () => void;
}

const MindDialog: React.FC<PropsType> = ({ isOpen, mindData, propsData, onlyClose, leaveNow }) => {
  console.log(mindData, "mindData");
  
  const [open, setOpen] = useState(false); // 控制弹窗的显示
  useEffect(() => {
    setOpen(isOpen); // 接收到 isOpen 的值时，重新设置 open 的值
  }, [isOpen]);
  // 关闭心智弹框
  const closeMindDialog = (reason: string) => {
    // 点击关闭按钮或者按 esc 键时，不执行关闭操作
    if (reason === "escapeKeyDown" || reason === "backdropClick" || reason === "close" || reason === "esc") {
      return;
    } else {
      onlyClose && onlyClose();
    }
  };
  return (
    <LoginDialog
      open={open}
      onClose={(e: React.MouseEvent<HTMLElement>, r: string) => closeMindDialog(r)}
      showCloseBtn={true}
      showReturnBtn={false}
      sx={{
        "& .MuiDialog-paper": {
          width: "400px", // 设置自定义宽度
          minHeight: "306px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent>
        <div className={style.mind__wrap}
         /** 挽留弹窗曝光 */
         exptag={propsData?.phoneOrEmail == "phone" ? "exp|" + MSG_CODE_EXPOSURE_RECORD.MsgCode_RetainExpo : "exp|" + EMAIL_PASSWORD_EXPOSURE_RECORD.EmailPassword_RetainExpo}
         data-exptag-config='{"useClick": false, "stay_duration": 0.9, "repeated":false, "area_rate": 0.01}'
         data-exptag-json-param={JSON.stringify({isNew: propsData?.userType === "new" ? '1' : '0'})}
        >
          <div className={style.mind__header}>
            <div className={style.title}>{mindData?.serviceTitle || 'One simple step to receive gifts'}</div>
          </div>
          <div className={style.main__content}>
            <div className={style.content} dangerouslySetInnerHTML={{__html: mindData?.serviceContent || ''}}></div>
          </div>
          <div className={style.main__footer}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                if (propsData?.phoneOrEmail == "phone") {
                  /** 点击事件上报-挽留弹窗-离开 */
                  msgCodeSendClickTrack.MsgCode_RetainExpo_Leave({isNew: propsData?.userType === "new" ? "1" : "0"})
                } else {
                  emailPasswordSendClickTrack.EmailPassword_RetainExpo_Leave({isNew: propsData?.userType === "new" ? "1" : "0"})
                }

                leaveNow?.();
              }}
              sx={{
                height: "32px",
                fontSize: "14px",
                margin: "16px 8px 10px 0",
                backgroundColor: "#fff", // 自定义背景颜色
                boxShadow: "none",
                color: "#1a1a1a", // 自定义文字颜色
                textTransform: "none",
                border: "1px solid #E0E1E5",
                "&:hover": {
                  backgroundColor: "#fefefe", // 悬停时的背景颜色
                },
              }}
            >
              Leave now
            </Button>
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                /** 点击事件上报-挽留弹窗-继续注册 */
                if (propsData?.phoneOrEmail == "phone") {
                  msgCodeSendClickTrack.MsgCode_RetainExpo_Continue({isNew: propsData?.userType === "new" ? "1" : "0"})
                } else {
                  emailPasswordSendClickTrack.EmailPassword_RetainExpo_Continue({isNew: propsData?.userType === "new" ? "1" : "0"})
                }
                onlyClose?.()
              }}
              sx={{
                height: "32px",
                fontSize: "14px",
                margin: "16px 0px 10px 8px",
                backgroundColor: "#CC0C1C", // 自定义背景颜色
                color: "#fff", // 自定义文字颜色
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#990915", // 悬停时的背景颜色
                },
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </DialogContent>
    </LoginDialog>
  );
};

export default MindDialog;
