// import JSEncrypt from '../jsencrypt';
import JSEncrypt from 'jsencrypt';

import { PUB_KEY, ReturnurlList } from './constants';
import loginConfig from '@/constants/loginConfig'
import CookieUtils from '@/utils/cookieUtils';

// const jsEncrypt = new JSEncrypt();
// jsEncrypt.setPublicKey(PUB_KEY);
/**
 * @desc 加密服务端返回的默认值，如果含有 * 号，则返回undefined，否则返回加密后的字符串(The default value returned by the encryption server. If it contains the * sign, it returns undefined, otherwise it returns the encrypted string.)
 */
export const getEncryptDefaultString = (str) => {
  if (typeof str === 'number') str = `${str}`;
  return typeof str === 'string' && str.indexOf('*') === -1 ? encrypt(str) : undefined;
};
export function encrypt(args) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(PUB_KEY);
  let str = typeof args == 'string' ? args : JSON.stringify(args);
  return encodeURIComponent(jsEncrypt.encrypt(str));
}
// 解密
export function decryptLong(args) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(PUB_KEY);
  let str = typeof args == 'string' ? args : JSON.stringify(args);
  return encodeURIComponent(jsEncrypt.decrypt(str));
}
/**
 * 从电子邮件地址中提取域名部分。
 * @param {string} email - 电子邮件地址。
 * @returns {string|null} 域名部分，如果电子邮件地址无效则返回 null。
 */
export function getDomainFromEmail(email) {
  const atSymbolIndex = email.indexOf('@');
  if (atSymbolIndex === -1) {
    return null; // 如果没有找到 @ 符号，返回 null
  }
  return email.substring(atSymbolIndex + 1);
}

/**
 * 从电子邮件地址中提取域名部分。
 * @param {string} email - 电子邮件地址。
 * @returns {string|null} - 域名部分，如果无法解析则返回 null。
 */
export function getDomainFromEmailNoCom(email) {
  const atSymbolIndex = email.indexOf('@');
  if (atSymbolIndex === -1) {
    return null; // 如果没有找到 @ 符号，返回 null
  }
  const domainStartIndex = atSymbolIndex + 1;
  const dotComIndex = email.indexOf('.com', domainStartIndex);
  if (dotComIndex === -1) {
    return null; // 如果没有找到.com，返回 null
  }
  return email.substring(domainStartIndex, dotComIndex);
}

/**
 * 根据国家代码返回国家名称和代码。
 * @param {string} countryCode - 国家代码。
 * @returns {string} 国家名称和代码，或“Country not found”。
 */
export function PhoneNumberUtils(number) {
  const countryCode = parseInt(number, 10)
  const countryList = [
    { code: '86', name: 'CN' },
    { code: '1', name: 'US' },
    { code: '44', name: 'GB' },
    { code: '49', name: 'DE' },
    { code: '33', name: 'FR' },
    // 继续添加其他国家代码和名称
  ]
  const country = countryList.find(c => c.code == countryCode);
  if (country) {
    return `${country.name} (+${country.code})`;
  } else {
    return 'Country not found';
  }
}

// 防抖函数
export function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

// 节流函数
export function throttle(func, limit) {
  let inThrottle;
  return function (...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  };
}

/**
    * @desc 获取url参数
    * @param path
    * @param params
    * @returns
    */
export function UrlParamsParse() {
  let search = location.search;
  if (!search) {
    return {};
  }
  let originParams = QS.parse(search.slice(1));
  let newParams = {};
  if (Object.keys(originParams).length) {
    Object.keys(originParams).forEach((key) => {
      if (ReturnurlList.includes(key)) {
        newParams["returnUrl"] = originParams[key];
      } else {
        newParams[key] = originParams[key];
      }
    })
  }
  return newParams;
}

/**
 * @desc 获取当前端的语言Cookie(Get the language cookie of the current end)
 * [注] Cookie由configon配置，如果获取及解析configon报错，则通过此函数动态计算cookie的key
 * [Note] Cookie is configured by configon. If an error is reported when obtaining and parsing configon, the cookie key is dynamically calculated through this function.
 */
export function getClient() {
  let result = "";
  let pathname = location.pathname;
  if (pathname.indexOf(`/seller/`) > -1) {
    result = 'seller'
  }
  // C(user)端
  else if (pathname.indexOf(`/user/`) > -1) {
    result = 'user'
  }
  // E(oper)端
  else if (pathname.indexOf(`/oper/`) > -1) {
    result = 'oper'
  }
  // EJ(jnos)端
  else if (pathname.indexOf(`/jnos/`) > -1) {
    result = 'jnos'
  }
  // BO(open)端
  else if (pathname.indexOf(`/open/`) > -1) {
    result = 'open'
  }
  return result;
}


export function setUserInfoToCookie(mobile, email) {
  const { href } = window.location;
  if (href.indexOf(`/user`) >= 0) {
    const domain = CookieUtils.getCookieDomainByHostname();
    if (mobile) {
      CookieUtils.setCookie(loginConfig.LoginCookieStorageType.COOKIE_C_PHONE, mobile, 1, domain);
    }
    if (email) {
      CookieUtils.setCookie(loginConfig.LoginCookieStorageType.COOKIE_C_EMAIL, email, 1, domain);
    }
  }
}
/**
 * @desc 存储登录相关Code，SDK会读取(Store the login related code and the SDK will read it.)
 * @param props 主工程下发的数据(Data issued by the main project)
 */
export function saveCommonCode(props) {
  const { productCode = ProductCodeEnum.B2C, appCode = "", tc = "" } = CommonUtils.UrlParamsParse();
  let _productCode = props?.CONSTANTS?.productCode || productCode;
  CookieUtils.setLocalStorage(Constants.PRODUCT_CODE_KEY, _productCode);
  CookieUtils.setSessionStorage(Constants.TENANT_CODE_KEY, tc);
  CookieUtils.setSessionStorage(Constants.APP_CODE_KEY, appCode);
}

/**
 * @desc 获取当前语言(Get current language)
 */
export function getLanguageByCookie(cookie) {
  let result = "";
  if (cookie) {
    result = CookieUtils.getCookie(cookie);
  }
  return result;
}

/**
 * @desc 手机号掩码
 */
export function MobileMask(phone) {
  return phone.replace(/(\d{1,3})\d{1,4}(\d{1,4})/, '$1****$2')
}

// 名称校验：只支持中文、英文、数字、-、_ 不能是纯数字
export function isInvalidName(value) {
  return !/^(?![0-9]+$)[\u4e00-\u9fa50-9a-zA-Z_-]{1,}$/.test(value);
}
// 密码校验：密码长度 8-20 位，需同时包含大小写字母、数字、特殊字符($@#!^%*?&-_)
export function isValidatePassword(password) {
  // const reg = /^(?![A-Za-z0-9]+$)(?![a-z0-9\\W]+$)(?![A-Za-z\\W]+$)(?![A-Z0-9\\W]+$)[a-zA-Z0-9_\\-\\W]{8,20}$/;
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\(\)\[\]\{\}\<\>\|\\\/\+\-\=\_\~!@#$%^&*:;"',.?·])[A-Za-z\d\(\)\[\]\{\}\<\>\|\\\/\+\-\=\_\~!@#$%^&*:;"',.?·]{8,20}$/;
  return reg.test(password);
}
// 邮箱校验
export function isValidateEmail(email) {
  const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email);
}
