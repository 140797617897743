/* eslint-disable react/display-name */
"use client";

import React, { useState, forwardRef, useEffect } from "react";
import LoginDefault from "./components/loginDefault/loginDefaultPage"; // 登录页面
import OldUserLogin from "./components/oldUserLogin/lodUserLoginPage"; // 老用户登录
import PhoneEmailLogin from "./components/phoneEmailLogin/phoneEmailLoginPage"; // 手机邮箱登录
import ForgotPassword from "./components/forgetPassword/forgetPassword"; // 忘记密码

const NewLoginDialog = forwardRef(({ open, IndexProps, callback, props, closeFn }, ref) => {
  const [paramsData, setParamsData] = useState({});
  const [oldParamsData, setOldParamsData] = useState({}); // 老用户页面需要的数据
  const [isForgot, setIsForgot] = useState(false); // 忘记密码
  const [forgotData, setForgotData] = useState({}); // 忘记密码数据
  const [pageFlag, setPageFlag] = useState("loginDefault"); // 打开某个页面 loginDefault oldUserLogin phoneEmailLogin forgotPassword

  useEffect(() => {
    !IndexProps && setPageFlag("loginDefault");
    if (IndexProps && Object.keys(IndexProps).length > 0) {
      setPageFlag("oldUserLogin"); // 打开老用户登录页面
      setOldParamsData(IndexProps);
    }
  }, [IndexProps]);
  // 登录页面点击 Continue;
  const loginContinue = (props) => {
    setParamsData(props && props);
    setPageFlag("phoneEmailLogin");
  };
  // 跳转到 登陆页面
  const goLoginDefault = (props) => {
    props && setParamsData(props);
    setPageFlag("loginDefault");
  };
  // 老用户跳转到登录弹框
  const oldGoPhoneOrEmail = (props) => {
    props && setParamsData(props);
    setPageFlag("phoneEmailLogin");
  };

  // 忘记密码
  const ForgotPasswordFn = (props) => {
    setPageFlag("forgotPassword");
    setForgotData(props);
    setIsForgot(true);
  };

  const closeDialog = () => {
    closeFn && closeFn(true);
  };

  return (
    <>
      {pageFlag == "loginDefault" && <LoginDefault props={props} mindFlag={false} loginContinue={loginContinue} propsData={paramsData} />}
      {pageFlag == "oldUserLogin" && (
        <OldUserLogin mindFlag={false} oldParamsData={oldParamsData} oldGoLoginDefault={goLoginDefault} oldGoPhoneOrEmail={oldGoPhoneOrEmail} />
      )}
      {pageFlag == "phoneEmailLogin" && (
        <PhoneEmailLogin
          props={props}
          callback={callback}
          propsData={paramsData}
          goLoginDefault={goLoginDefault}
          ForgotPasswordFn={ForgotPasswordFn}
          closeDialog={() => {}}
        />
      )}
      {pageFlag == "forgotPassword" && <ForgotPassword isOpen={isForgot} propsData={forgotData} goLoginDefault={loginContinue} closeFn={closeDialog} />}
    </>
  );
});

export default NewLoginDialog;
