/* eslint-disable react-hooks/rules-of-hooks */
"use client";
import { MindDataType } from "../../type/index";
import { useState, useEffect } from "react";
import { uaParse } from "@/utils/uaParser";
import { fetchSettingsConsent } from "@/apis/help/help.api";
import { StorageKeysMap } from "../../../utils/login/constants";
import { MyBindWayEnum, LoginTypeEnum } from "@/constants/enumLogin";
import { imageUtil } from "@/common-components-src/js/utils/imageUtil";
import { setDefaultLocalAddress } from "@/common-components-src/js/utils/addressRequestUtils";
import { parsePhoneNumberAndCodeTime, isWithinOneMinute, emailList } from "../../utils/login";
import { GoogleReCAPTCHAEnum, CaptchaActionEnum } from "@/common-components-src/js/utils/login/constants";
import { validateEmail, judgeNewOrOldUserFn, extractPhoneNumber, splitAndProcess } from "../../utils/login";
import { InputStyle, SelectStyle, MenuProps, inputDefaultSx, inputRootDefaultSx, continueBtnSx } from "../../style/style";
import { sendOTP, getThirdAppAuthorizeUrl, thirdLoginApi, getCountryListApi } from "@/common-components-src/api/login/index.api";
import { Select, Button, MenuItem, IconButton, FormControl, InputAdornment, InputLabel, OutlinedInput, FormHelperText } from "@mui/material";

import style from "../../style.module.scss";
import cookieUtil from "@/utils/cookieUtils";
import Cancel from "@mui/icons-material/Cancel";
import loginConfig from "@/constants/loginConfig";
import LoginType from "@/common-components-src/const/login";
import LineSvg  from "@/common-components-src/assets/icons/lineSvg.svg";
import Apple  from "@/common-components-src/assets/icons/Apple.svg";
import Google  from "@/common-components-src/assets/icons/Google.svg";
import UKToast from "@/common-components-src/components/UKToast/index";
import ThirdAccountUtils from "../../../utils/login/ThirdAccountUtils";
import Facebook  from "@/common-components-src/assets/icons/Facebook.svg";
import JDIcon  from "@/common-components-src/assets/icons/jingDongIcon.svg";
import GreenLock  from "@/common-components-src/assets/icons/greenLock.svg";
import LoadingIcon from '@/common-components-src/components/loadingIcon/index';
import SecurePrivacy  from "@/common-components-src/assets/icons/securePrivacy.svg";
import InfoProtection  from "@/common-components-src/assets/icons/InfoProtection.svg";


import { sendClickTrack as newLoginSendClickTrack, EXPOSURE_RECORD as NEW_LOGIN_EXPOSURE_RECORD } from "@/tracks/25238";
import { sendPv as loginSendPv, sendClickTrack as loginSendClickTrack, sendKeyActionTrack as loginSendKeyActionTrack, EXPOSURE_RECORD as LOGIN_EXPOSURE_RECORD } from "@/tracks/25225";

interface PropsType {
  propsData?: any; // 其他数据
  mindFlag?: boolean;
  mindData: MindDataType[];
  props: { isRefresh?: boolean; isForgotPassword?: boolean; isReturnUrl?: boolean }; // 登录时传入参数 控制是否强制刷新页面等
  isDialog?: boolean; // 判断是否是弹框还是页面
  closeFN?: () => void;
  callback?: (props?: any) => void; // 登录成功后的回调函数
  loginContinue?: (props?: any) => void; // 关闭弹框的函数
}

const loginDefault = ({ isDialog = false, mindData, mindFlag, propsData, props, loginContinue, callback, closeFN }: PropsType) => {
  const [returnUrl, setReturnUrl] = useState("/");
  const [idPrefix, setIdPrefix] = useState<any>(); // 手机号国家标识
  const [error, setError] = useState<string | null>(""); // 错误提示
  const [inputVal, setInputVal] = useState<string | null>(""); // 输入框的值
  const [isApple, setIsApple] = useState<boolean>(false); // 是否是 Apple 登录
  const [selectFlag, setSelectFlag] = useState<boolean>(false); // 是否显示 select 盒子
  const [emailListFlag, setEmailListFlag] = useState<any>(false); // 邮箱列表
  const [emailData, setEmailData] = useState(emailList); // 邮箱列表
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true); // 是否禁用 continue 按钮
  const [formatNumberVal, setFormatNumber] = useState<string | null>(""); // 格式化后的手机号码
  const [btnLoading, setBtnLoading] = useState<boolean>(false); // 是否禁用 continue 按钮
  const [countryList, setCountryList] = useState<any>([]);
  const __w: any = window;
  const urlExtParams = splitAndProcess();

  useEffect(() => {
    loginSendPv.NewLogin()
  }, [])

  /* ------------------------------------------------------------------------------- 页面默认配置 ----------------------------------------------------------------------------------- */
  // 判断当前是否是 Apple 设备
  useEffect(() => {
    const uaResult: any = uaParse();
    const r = uaResult?.platform.vendor;
    if (r == "Apple" || r == "Mac" || r == "mac" || r == "IOS" || r == "ios") {
      setIsApple(true);
    } else {
      setIsApple(false);
    }
    const urlParams = new URLSearchParams(window.location.search);
    const url = urlParams.get("returnUrl");

    url && setReturnUrl(url);
  }, []);
  /* ------------------------------------------------------------------------------- 输入框校验 ----------------------------------------------------------------------------------- */
  // 如果有值的话；是上一层点击编辑按钮过来的；
  useEffect(() => {
    if (propsData && Object.keys(propsData).length > 0) {
      propsData.identifier && setInputVal(propsData.identifier);
      if (!isNaN(propsData?.identifier) && propsData?.identifier?.length > 0 && propsData?.identifier?.length < 14) {
        // 如果是纯数字
        setSelectFlag(true);
        setFormatNumber(propsData.identifier)
        setIdPrefix(Number(extractPhoneNumber(propsData.idPrefix)))
        console.log(propsData.idPrefix,'ssss');
        
        getCountryList()
      } else if (!/^[a-zA-Z]+$/.test(propsData.identifier)) {
        // 如果是邮箱
        setSelectFlag(false);
        setFormatNumber(propsData.identifier);
      } else {
        setSelectFlag(false);
        setFormatNumber(propsData.identifier);
      }
    }
  }, [propsData]);
  // 输入框的值改变
  useEffect(() => {
    if (inputVal && inputVal.trim() !== "") {
      setBtnDisabled(false);
    } else {
      setSelectFlag(false);
      setBtnDisabled(true);
    }
    setError("");
    inputVal && inputPattern(inputVal);
  }, [inputVal]);

  useEffect(()=>{
    getCountryList();
  }, [])

  const getCountryList = async () => {
    const [err, res] = await getCountryListApi();
    if (res?.success) {
      setCountryList(res?.data?.internationalData?.mobileLocations)
      if (propsData?.idPrefix) {
        return;
      }
      const v = res?.data?.internationalData
      v?.defaultMobileLocations && v?.defaultMobileLocations.length > 0 && setIdPrefix(parseFloat(v?.defaultMobileLocations[0].code))
    }
  }
  // 输入框校验
  const inputPattern = (v: any) => {
    const value = v.replace(/\s+/g, ''); // 去除所有空格
    if (!isNaN(value) && value.length > 0 && value.length < 14) {
      // 如果是纯数字
      setSelectFlag(true);
    } else if (!/^[a-zA-Z]+$/.test(v)) {
      // 如果是邮箱
      setSelectFlag(false);
    } else {
      setSelectFlag(false);
    }
  };

  // 输入框改变事件
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: any = e.target.value.replace(/\s+/g, ''); // 去除所有空格
    setInputVal(value.replace(/\s+/g, ''));
    setFormatNumber(value);
    // formatNumber(value);
    if (value.includes('@')) {
      // 截取 @ 后的字符，并且使用@后的字符 与 emailList 进行精准匹配
      setEmailListFlag(true);
      const emailSuffix = value.slice(value.indexOf('@') + 1);
      const list = emailList.filter((item: any) => item.slice(1, emailSuffix.length + 1).toLowerCase() == emailSuffix.toLowerCase())
      if (list.length > 0) {
        setEmailData(list);
      } else {
        setEmailData([]);
      }
    } else {
      setEmailListFlag(false);
    }
  };

  // 手机号码校验
  const phonePattern = async (v: any) => {
    if (v.length > 14 || v.length < 8) {
      setError("Incorrect phone number");
    } else {
      getSessionIdSDk();
    }
  };
  // 邮箱校验
  const emailPattern = (v: any) => {
    if (validateEmail(v)) {
      const allowedChars = /^[A-Za-z0-9._+-]+@[A-Za-z0-9._+-]+\.[A-Za-z._+-]{2,}$/;
      if (!allowedChars.test(v)) {
        setError("Please enter a valid email address");
      } else {
        emailContinueFn("")
      }
    } else {
      setError("Please enter a valid email address");
    }
  };
  // 键盘摁下事件
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      continueSubmit();
    }
  };
  // 点击 continue 按钮
  const continueSubmit = () => {
    if (inputVal && inputVal.trim() !== "") {
      const v = inputVal.replace(/\s+/g, '')
      if (/^\d+$/.test(v) && v.length < 14) {
        phonePattern(v);
      } else {
        emailPattern(v);
      }
    } else {
      setError("Please enter your phone number or email address");
    }
  };
  /* ------------------------------------------------------------------------------- 验证码登录 ----------------------------------------------------------------------------------- */

  // 手机号登录线上调用 sessionID
  const getSessionIdSDk = async () => {
    setBtnLoading(true);
    const { userType, wl } = await judgeNewOrOldUserFn({
      type: "phone",
      identifier: inputVal as string,
      PhoneSign: idPrefix,
    });

    /** 埋点 - 继续按钮点击 */
    /** 登录注册类型 1-phone,2-email,3-谷歌账户,4-facebook账户,5-京东账户,6-苹果账户 */
    if(isDialog) {
      newLoginSendClickTrack.NewLoginPopup_Continue({loginRegisterType: "1", isNew: userType == "new" ? "1" : "0"})
    } else {
      loginSendClickTrack.NewLogin_Continue({loginRegisterType: "1", isNew: userType == "new" ? "1" : "0"})
    }

    // 是否白名单 true:不需要生成token，不需要走google验证
    if (wl) {
      sendOTPCode("", userType);
    } else {
      const w: any = window;
      if (!w.grecaptcha) {
        setBtnLoading(false);
        UKToast.show({icon: "warning",content: "Request Google Grecaptcha Enterprise Error"})
        return;
      } else {
        w.grecaptcha.enterprise.ready(async () => {
          const token = await (w)?.grecaptcha.enterprise.execute(GoogleReCAPTCHAEnum.KEY_ID, {action: CaptchaActionEnum.SIGNUP});
          sendOTPCode(token, userType);
        });
      }
    }
  };

  // 发送验证码
  const sendOTPCode = async (token: string | null, userType: string) => {
    if(!idPrefix) {
      UKToast.show({
        icon: "warning",
        content: "Please refresh and retry.",
      })
      return;
    }
    setBtnLoading(true);
    const data = parsePhoneNumberAndCodeTime();
    const times = cookieUtil.getSessionStorage("codeCloseTime");
    // flag 为 true 表示在 1 分钟内 否则 不在 1 分钟内
    let flag = false;
    if (times) {
      flag = isWithinOneMinute(Number(times));
      if (!flag) {
        // 不在一分钟内 清除 codeCloseTime
        cookieUtil.clearSessionStorage("codeCloseTime");
        // 清除手机号
        cookieUtil.clearSessionStorage("phoneAndCodeTime");
      }
    }
    if (Number(data.codeTime) > 0 && data?.phoneNumber == inputVal && propsData?.idPrefix == idPrefix && flag) {
      const params: any = {
        otpType: 2,
        idPrefix: "+" + idPrefix,
        identifier: inputVal,
        scene: LoginType.VerifyCodeSceneEnum.LOGIN,
        captchaCode: token,
        captchaAction: CaptchaActionEnum.SIGNUP,
      };
      loginContinue &&
        loginContinue({
          ...params,
          userType: "old",
          stepCode: cookieUtil.getSessionStorage("stepCode"),
          phoneOrEmail: "phone", // 区分手机号还是邮箱
          phoneCodeTime: true,
        });
    } else {
      cookieUtil.clearSessionStorage("codeCloseTime");
      cookieUtil.clearSessionStorage("phoneAndCodeTime");
      try {
        const params: any = {
          otpType: 2,
          idPrefix: "+" + idPrefix,
          identifier: inputVal,
          scene: LoginType.VerifyCodeSceneEnum.LOGIN,
          captchaCode: token,
          captchaAction: CaptchaActionEnum.SIGNUP,
        };
        console.log('yz log params', params);

        const [err, res]: any = await sendOTP(params);
        setBtnLoading(false);
        if (res && res.success) {
          UKToast.show({
            content: "Verification code sent successfully",
            icon: "success",
          });
          cookieUtil.setSessionStorage("stepCode", res.data);
          loginContinue &&
            loginContinue({
              ...params,
              userType,
              stepCode: res.data,
              phoneOrEmail: "phone", // 区分手机号还是邮箱
            });
        } else {
          UKToast.show({
            content: res.msg || "Verification code sent fail",
            icon: "error",
          });
        }
      } catch (error) {
        setBtnLoading(false);
        console.log(error);
      }
    }


  };
  /* ------------------------------------------------------------------------------- 邮箱登录 ----------------------------------------------------------------------------------- */
  // 邮箱获取是否是新老用户，跳转到输入密码页面；
  const emailContinueFn = async (token: string | null) => {
    setBtnLoading(true);
    const { userType } = await judgeNewOrOldUserFn({
      type: "email",
      identifier: inputVal as string,
    });

    /** 埋点 - 继续按钮点击 */
    /** 登录注册类型 1-phone,2-email,3-谷歌账户,4-facebook账户,5-京东账户,6-苹果账户 */
    loginSendClickTrack.NewLogin_Continue({loginRegisterType: "2", isNew: userType == "new" ? "1" : "0"})
    const params: any = {
      otpType: 3,
      identifier: inputVal,
      scene: LoginType.VerifyCodeSceneEnum.LOGIN,
      captchaCode: token,
      captchaAction: CaptchaActionEnum.SIGNUP,
    };
    loginContinue &&
      loginContinue({
        ...params,
        userType,
        phoneOrEmail: "email", // 区分手机号还是邮箱
      });
    setBtnLoading(false);
  };
  // 替换邮箱后缀
  const replaceAfterAtSymbol = (str: string, replacement: string) => {
    const atSymbolIndex = str.indexOf('@');
    if (atSymbolIndex === -1) {
      return str + replacement;
    }
    return str.slice(0, atSymbolIndex) + replacement;
  };
  // 邮箱列表点击事件
  const handleEmailListClick = (item: any) => {
    const replacedEmail = replaceAfterAtSymbol(inputVal as string, item);
    setInputVal(replacedEmail);
    setFormatNumber(replacedEmail);
    setEmailListFlag(false);
  }

  /* ------------------------------------------------------------------------------- 第三方登录 ----------------------------------------------------------------------------------- */
  // 第三方授权登录
  const AccountListFn = async (code: number) => {
      switch (Number(code)) {
        case LoginTypeEnum.GOOGLE:
        /** 埋点 - 谷歌账号快速登录 */
        if (isDialog){ newLoginSendClickTrack.NewLoginPopup_Google({isNew: "1"})}
        else loginSendClickTrack.NewLogin_Google()
        break;
      case LoginTypeEnum.FACEBOOK:
        /** 埋点 - 点击事件上报-facebook账号快速登录 */
        if (isDialog){ newLoginSendClickTrack.NewLoginPopup_Facebook({isNew: "1"})}
        else loginSendClickTrack.NewLogin_Facebook()
        break;
      case LoginTypeEnum.APPLE:
        /** 埋点 - 点击事件上报-苹果账号快速登录 */
        if (isDialog){ newLoginSendClickTrack.NewLoginPopup_Apple({isNew: "1"})}
        else loginSendClickTrack.NewLogin_Apple()
        break;
      case LoginTypeEnum.JD:
        /** 埋点 - 点击事件上报-京东账号快速登录 */
        if (isDialog){ newLoginSendClickTrack.NewLoginPopup_JD({isNew: "1"})}
        else loginSendClickTrack.NewLogin_JD()
        break;
      default:
        break;
    }
    const extension = urlExtParams && Object.keys(urlExtParams).length > 0 ? {extension: JSON.stringify({...urlExtParams})} : null
    try {
      const [err, res]: any = await getThirdAppAuthorizeUrl({
        loginType: code,
        ...extension,
        finalRedirectUrl: returnUrl ? encodeURIComponent(returnUrl) : encodeURIComponent(location.href),
      });
      if (res && res.success) {
        cookieUtil.setLocalStorage(
          StorageKeysMap.urlParamsToBind,
          JSON.stringify({
            loginType: code,
            myBindWay: MyBindWayEnum.REDIRECT_URI,
          }),
        );
        switch (Number(code)) {
          case LoginTypeEnum.GOOGLE:
            loginByGoogle(res.data);
            break;
          case LoginTypeEnum.FACEBOOK:
            loginByFacebook(res.data);
            break;
          case LoginTypeEnum.APPLE:
            loginByApple(res.data);
            break;
          case LoginTypeEnum.JD:
            loginByJD(res.data);
            break;
          default:
            break;
        }
      } else {
        UKToast.show({
          icon: "error",
          content: res.msg || "error",
        });
      }
    } catch (error) { }
  };
  // Google 登录
  const loginByGoogle = (authorizeInfo: any) => {
    ThirdAccountUtils.initGoogleAccount(
      authorizeInfo,
      (token: string) => {jumpToBind({loginType: LoginTypeEnum.GOOGLE, code: token, state: authorizeInfo.state});},
      (msg: string | null) => {
          UKToast.show({
              icon: "error",
              content: msg || "",
          });
      },
      () => {},
    );
  };
  // Facebook 登录
  const loginByFacebook = (authorizeInfo: any) => {
    ThirdAccountUtils.initFacebookAccount(
      authorizeInfo,
      (token: string) => {jumpToBind({loginType: LoginTypeEnum.FACEBOOK, accessToken: token, state: authorizeInfo.state});},
      (msg: string) => {
          UKToast.show({
              icon: "error",
              content: msg,
          });
      },
      () => {},
    );
  };
  // Apple 登录
  const loginByApple = (authorizeInfo: any) => {
    ThirdAccountUtils.initAppleAccount(
      authorizeInfo,
      (token: string) => {
          jumpToBind({loginType: LoginTypeEnum.APPLE,idToken: token,state: authorizeInfo.state});
      },
      (msg: string) => {
          UKToast.show({
              icon: "error",
              content: msg,
          });
      },
      () => {},
    );
  };
  // 京东 登录
  const loginByJD = (authorizeInfo: any) => {
    location.href = authorizeInfo.authorizeUri;
  }
  type jumpToBindParams = {loginType: number, idToken?: string, state: string , code?: string, accessToken?: string}
  // 跳转到相应的路由
  const jumpToBind = async (data: jumpToBindParams) => {
    try {
      const [err, res]: any = await thirdLoginApi(data);
      if (res && res.success) {
        await fetchSettingsConsent();
          /** 三方登录点击按钮 **/
          switch (Number(data.loginType)) {
          case LoginTypeEnum.GOOGLE:
              // if (isDialog) {NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_LoginResult} { loginRegisterType: '3', isNew:  res?.authnRegister ? '1' : '0', is_success: res?.success ? "1" : "0"}
              loginSendKeyActionTrack.NewLogin_LoginResult({ loginRegisterType: '3', isNew:  res?.authnRegister ? '1' : '0', is_success: res?.success ? "1" : "0"});
              break;
          case LoginTypeEnum.FACEBOOK:
              loginSendKeyActionTrack.NewLogin_LoginResult({ loginRegisterType: '4', isNew:  res?.authnRegister ? '1' : '0', is_success: res?.success ? "1" : "0"});
              break; 
          case LoginTypeEnum.APPLE:
              loginSendKeyActionTrack.NewLogin_LoginResult({ loginRegisterType: '5', isNew:  res?.authnRegister ? '1' : '0', is_success: res?.success ? "1" : "0"});
              break;
          case LoginTypeEnum.JD:
              loginSendKeyActionTrack.NewLogin_LoginResult({ loginRegisterType: '6', isNew:  res?.authnRegister ? '1' : '0', is_success: res?.success ? "1" : "0"});
              break;
          default:
              break;
        }

        cookieUtil.deleteCookie(loginConfig.loginLocalInfo.USER_SUCCESS_ACCOUNT);
        UKToast.show({
          content: "Login success",
          icon: "success",
        });
        await setDefaultLocalAddress();
        __w.JDIheader && __w.JDIheader.initUserInfo();
        __w.JDIheader && __w.JDIheader.initCartNum();
        if (props && !props.isRefresh) return;
        if (props && props.isRefresh && !props.isReturnUrl) location.reload();
        if (props && props.isForgotPassword && !props.isReturnUrl) location.href = "/";
        if (returnUrl && props.isReturnUrl) window.location.href = returnUrl;
        closeFN && closeFN();
        callback && callback({
          loginSuccessData: res.data,
          login_type: "email_login",
          login_type_name: "login_success",
        });
      } else {
        UKToast.show({
          icon: "error",
          content: res.msg || "error",
        });
      }
    } catch (error) { }
  };
  // 协议 PrivacyFn 点击事件
  const agreementPrivacyFn = () => {
    /** 点击事件上报-条款按钮点击 */
    if (isDialog) {newLoginSendClickTrack.NewLoginPopup_Privacy()}
    else {loginSendClickTrack.NewLogin_Privacy()}
    location.href = "/help/privacy-policy";
  };
  // 协议 TermsFn 点击事件
  const agreementTermsFn = () => {
    /** 点击事件上报-条款按钮点击 */
    if (isDialog) {newLoginSendClickTrack.NewLoginPopup_Term()}
    else {loginSendClickTrack.NewLogin_Term()}
    location.href = "/help/terms-conditions";
  };


  return (
    <div className={style.loginDefault}>
      {/* 头部标题 */}
      <div className={style.header}>
        <img src="https://st.joy-sourcing.com/website/other/newlogo.jpeg" alt="JoyBuy" />
        <div className={style.header_title}>
          <GreenLock />
          <span>Data encryption</span>
        </div>
      </div>
      {/* 登录表单 */}
      <div className={style.login__form}>
        <div style={{ position: "relative" }}>
          <FormControl
            error={!!error}
            sx={{
              ...InputStyle,
              ...inputDefaultSx,
              "& .MuiInputBase-root": {
                ...inputRootDefaultSx,
                "& .MuiInputBase-input": {
                  maxWidth: `${!selectFlag ? "100%" : "65%"}`, // 设置宽度
                  whiteSpace: "nowrap",
                  textAlign: selectFlag ? "left" : "center",
                },
              },
            }}
            variant="outlined"
          >
            <InputLabel htmlFor="Mobile number or email address">Mobile number or email address</InputLabel>
            <OutlinedInput
              type="text"
              value={formatNumberVal}
              onChange={handleChange}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
              startAdornment={
                selectFlag && (
                  <InputAdornment position="start">
                    <Select
                      labelId="simple-select-autowidth-label"
                      id="simple-select-autowidth"
                      autoWidth
                      value={idPrefix}
                      onChange={(event: any) => {
                        if (isDialog) {newLoginSendClickTrack.NewLoginPopup_PhoneSwitch()}
                        else {loginSendClickTrack.NewLogin_PhoneSwitch()}
                        setIdPrefix(Number(event.target.value))
                      }}
                      renderValue={(value) => {
                        const s = countryList?.find((opt: any) => opt.code == value);                        
                        return s ? s?.name + ` (${s?.code})` : `GB (+44)`;
                      }}
                      MenuProps={MenuProps as any}
                      sx={{
                        ...SelectStyle,
                      }}
                      
                      exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_PhoneSwitchExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_PhoneSwitchExpo}
                      data-exptag-config='{"useClick": false, "stay_duration": 0.6, "repeated":false, "area_rate": 0.5}'
                    >
                      {
                        countryList?.length > 0 ? countryList.map((item: any, index: number) => {
                          return <MenuItem key={index} value={parseFloat(item.code)} >{item.country}{" "}{item.code}</MenuItem>
                        }) : <MenuItem value={44}>GB(+44)</MenuItem>
                      }
                    </Select>
                  </InputAdornment>
                )
              }
              endAdornment={
                <InputAdornment position="end">
                  {inputVal && (
                    <IconButton
                      onClick={() => {
                        setError("");
                        setInputVal("");
                        setFormatNumber("");
                      }}
                    >
                      <Cancel sx={{ fontSize: 18 }} />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
            {!!error && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
          {emailListFlag && emailData?.length > 0 && (
            <div className={style.emailListStyle}>
              {
                emailData.map((item: any, index: number) => (
                  <div key={index} className={style.item} onClick={() => handleEmailListClick(item)}>
                    <span className={style.spanText}>{formatNumberVal?.slice(0, formatNumberVal?.indexOf('@'))}</span><em className={style.emText}>{item}</em>
                  </div>
                ))
              }
            </div>
          )}
        </div>
        <div className={style.btn}>
          <Button
            variant="contained"
            disableElevation
            disabled={btnDisabled || btnLoading}
            onClick={continueSubmit}
            startIcon={btnLoading ? <LoadingIcon /> : null}
            sx={{...continueBtnSx}}
            exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_ContinueExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_ContinueExpo}
            data-exptag-config='{"useClick": false, "stay_duration": 0.6, "repeated":false, "area_rate": 0.5}'
          >
            Continue
          </Button>
        </div>
      </div>
      {/* OR Join With 盒子 */}
      {/* 第三方登录按钮 */}
      <div className={style.orJoinWith__ThirdParty}>
        <div className={style.orJoinWith}>
          <LineSvg />
          <span>Or join with</span>
          <LineSvg />
        </div>
        <div className={style.loginThirdParty_btn}>
          <div className={style.loginThirdParty_btn_item}
          /** 京东账号快速登录曝光 */
          exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_JDExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_JDExpo} 
          data-exptag-config='{"useClick": false, "stay_duration": 0.6, "repeated":false, "area_rate": 0.01}'
          >
            <JDIcon onClick={() => AccountListFn(4)} />
            <span onClick={() => AccountListFn(4)}>JINGDONG</span>
          </div>
          <div className={style.loginThirdParty_btn_item}
          /** 谷歌账号快速登录曝光 */
          exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_GoogleExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_GoogleExpo} 
          data-exptag-config='{"useClick": false, "stay_duration": 0.6, "repeated":false, "area_rate": 0.01}'
          >
            <Google onClick={() => AccountListFn(25)} />
            <span onClick={() => AccountListFn(25)}>Google</span>
          </div>
          <div className={style.loginThirdParty_btn_item}
          /** facebook账号快速登录曝光 */
          exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_FacebookExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_FacebookExpo} 
          data-exptag-config='{"useClick": false, "stay_duration": 0.6, "repeated":false, "area_rate": 0.01}'
          >
            <Facebook onClick={() => AccountListFn(24)} />
            <span onClick={() => AccountListFn(24)}>Facebook</span>
          </div>

          {isApple && (
            <div className={style.loginThirdParty_btn_item}
            /** 苹果账号快速登录曝光 */
            exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_AppleExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_AppleExpo} 
            data-exptag-config='{"useClick": false, "stay_duration": 0.6, "repeated":false, "area_rate": 0.01}'
            >
              <Apple onClick={() => AccountListFn(23)} />
              <span onClick={() => AccountListFn(23)}>Apple</span>
            </div>
          )}
        </div>
      </div>
      {/* footer 底部 footer__page__wrap */}
      <div className={mindFlag ? style.footer__box : style.footer__page__wrap}>
        <div className={style.agreement}>
          <p>By continuing, you agree to our</p>
          <p>
            <span 
              exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_PrivacyExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_PrivacyExpo} 
              data-exptag-config='{"useClick": false, "stay_duration": 0.9, "repeated":false, "area_rate": 0.01}'
              onClick={() => agreementPrivacyFn()}
            >Privacy & Cookie Policy</span>
            and
            <span 
              exptag={isDialog ? "exp|" + NEW_LOGIN_EXPOSURE_RECORD.NewLoginPopup_TermExpo : "exp|" + LOGIN_EXPOSURE_RECORD.NewLogin_TermExpo} 
              data-exptag-config='{"useClick": false, "stay_duration": 0.9, "repeated":false, "area_rate": 0.01}'
              onClick={() => agreementTermsFn()}
            >Terms &Condition</span>
          </p>
        </div>
        {mindFlag && (
          <div className={style.mind}>
            <div className={style.mind__item}>
              {mindData && mindData.length > 0 && mindData[0].icon ? (
                <img src={mindData && imageUtil.concatImgUrlWithoutForceHttps(mindData[0].icon)} alt="" />
              ) : (
                <SecurePrivacy />
              )}
              <em>{mindData && mindData.length > 0 && mindData[0].serviceTitle}</em>
            </div>
            <div className={style.mind__item}>
              {mindData && mindData.length > 0 && mindData[1].icon ? (
                <img src={mindData && imageUtil.concatImgUrlWithoutForceHttps(mindData[1].icon)} alt="" />
              ) : (
                <InfoProtection />
              )}
              <em>{mindData && mindData.length > 0 ? mindData[1].serviceTitle : ""}</em>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default loginDefault;
