import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

// eslint-disable-next-line react/prop-types
function UKDialog({ open, onClose = (event, reason) => {}, onReturn = () => {}, showCloseBtn, showReturnBtn = false, disableBackdropClick = false, children, className = "", sx = {} }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={className}
      sx={sx}
      PaperProps={{
        sx: {
          maxWidth: "none",
        },
      }}
    >
      {React.Children.map(children, (child) => {
        if (showCloseBtn) {
          return (
            <child.type {...child.props} sx={{ position: "relative" }}>
              {child.props.children}
              {showReturnBtn && (
                <IconButton
                  onClick={onReturn}
                  sx={(theme) => ({
                    position: "absolute",
                    left: 10,
                    top: 10,
                    color: theme.palette.grey[500],
                  })}
                >
                  <ArrowBackIos sx={{ fontSize: 18 }} />
                </IconButton>
              )}

              <IconButton
                onClick={onClose}
                sx={(theme) => ({
                  position: "absolute",
                  right: 10,
                  top: 10,
                  color: theme.palette.grey[500],
                })}
              >
                <CloseIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </child.type>
          );
        }
        return child;
      })}
    </Dialog>
  );
}

export default UKDialog;
