import cookieUtils from "@/utils/cookieUtils";

import { Address, PostCodeAddress, DEF_ADDRESS_BODY } from "@/common-components-src/api/address/address.interface";
import { parseCookieString } from "@/utils/cookieUtils";

// Default address cookie name
export const ADDRESS_KEY = "USER_ADDRESS_AREA_KEY";
// Default cascade (postCode info) address cookie name
export const AREA = "CASCADE_ADDRESS_AREA_KEY";
// Default cookie timeout: Unit: days
export const EXPIRE_DAYS = 30;

// 初始化缓存中的地址
export const initAddress = (): Promise<Address | PostCodeAddress | null> => {
  return new Promise((resolve) => {
    const address = getAddress();
    if (address) {
      resolve(address as Address);
      return;
    }
    const cascadeArea = getArea();

    if (cascadeArea) {
      resolve(cascadeArea as PostCodeAddress);
      return;
    }
    resolve(null);
  });
};

// 四级地址等相关参数
export const areaAddress = async () => {
  const data = await initAddress();
  const body: { [key: string]: any } = {};
  // 获取缓存postCode
  if (data) {
    body["postCode"] = data?.postCode;
    body['area'] = data?.provinceId + "_" + data?.cityId + "_" + data?.districtId + "_" + data?.townId;
    return body;
  }
  return DEF_ADDRESS_BODY;
};

//获取当前缓存的状态
export const curIsLocal = async () => {
  const data = await initAddress();
  // 获取缓存postCode
  console.log("curIsLocal data login", data);
  if(data?.isDefaultLocal){
    return false;
  }
  if (data) {
    return true;
  }
  return false;
};

// 四级地址等相关参数(给ssr项目单独使用)
export const getSSrAddressBody = (cookie: string) => {
  // 这里通过传入的cookie，来获取对应的 getAddress 或者 getArea 最后把获取的值生成一个对象 回传回去
  let body: { [key: string]: any } = { ...DEF_ADDRESS_BODY };
  const customCookies = parseCookieString(cookie); // 先解析传入的cookie字符串为对象
  const addressStr = customCookies[ADDRESS_KEY];
  const areaStr = customCookies[AREA];
  if (addressStr) {
    try {
      const address = JSON.parse(decodeURIComponent(decodeURIComponent(addressStr)));
      if (address) {
        body['area'] = address?.provinceId + "_" + address?.cityId + "_" + address?.districtId + "_" + address?.townId;
        body['postCode'] = address?.postCode;
      }
    } catch (e) {
      console.log(e);
    }
  }
  if (areaStr) {
    try {
      const area = JSON.parse(decodeURIComponent(decodeURIComponent(areaStr)));
      if (area) {
        body['postCode'] = area?.postCode;
        body['area'] = area?.provinceId + "_" + area?.cityId + "_" + area?.districtId + "_" + area?.townId;
      }
    } catch (e) {
      console.log(e);
    }
  }
  return body;
};

export const isPostCodeAddress = (data: any) => {
  if (data === null) {
    return false;
  }
  return data?.postCode && !data?.addressId;
};

export const isAddress = (data: any) => {
  if (data === null) {
    return false;
  }
  return data?.postCode && data?.addressId;
};

/**
 * Get the user's shipping address in the cache
 * @description Get the user's delivery address information. The parameter structure is AddressVo or empty.
 * @return IAddressVo | string
 */

export const getAddress = (): Address | string => {
  const addressStr: string = cookieUtils.getCookie(ADDRESS_KEY) || "";
  if (addressStr) {
    try {
      const address = JSON.parse(decodeURI(addressStr));
      if (address) {
        return address;
      } else {
        return "";
      }
    } catch (e) {
      console.log(e);
      return "";
    }
  } else {
    return "";
  }
};

/** Set user shipping address information
 * @param address: AddressVo
 * @description Set the full address information, the input parameter is AddressVo
 * Parameter example: {"addressId": 1800020752,"countryId": 53333,"countryName": "Japan","provinceId": 58733,"provinceName": "YAMANASHI","cityId": 63611,"cityName": "KOFU "}
 */
export const setAddress = (address: Address) => {
  if (address) {
    try {
      const addressStr = encodeURI(JSON.stringify(address));
      cookieUtils.setCookie(ADDRESS_KEY, addressStr, EXPIRE_DAYS);
      // Set area
      // setArea(address);
      clearArea();
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * Get cascading address cache
 * @description Get the cascade address cache, parameter example =>
 */
export const getArea = (): PostCodeAddress | string => {
  const areaStr: string = cookieUtils.getCookie(AREA) || "";
  if (areaStr) {
    try {
      const area = JSON.parse(decodeURI(areaStr));
      if (area) {
        return area;
      } else {
        return "";
      }
    } catch (e) {
      console.log(e);
      return "";
    }
  } else {
    return "";
  }
};

/**
 * Set cascade address
 * @param area
 * @description Set cascade address cache, parameter example =>
 */
export const setArea = (area: PostCodeAddress) => {
  if (area) {
    try {
      const areaStr = encodeURI(JSON.stringify(area));
      cookieUtils.setCookie(AREA, areaStr, EXPIRE_DAYS);
      clearAddress();
    } catch (e) {
      console.log(e);
    }
  }
};

/**
 * Clear the cascade address cache
 * @description Clear the cascade address cache
 */
export const clearArea = () => {
  try {
    cookieUtils.setCookie(AREA, "", EXPIRE_DAYS);
  } catch (e) {
    console.log(e);
  }
};

/**
 * Clear user shipping address information
 * @description Clear user shipping address cache
 */
export const clearAddress = () => {
  try {
    cookieUtils.setCookie(ADDRESS_KEY, "", EXPIRE_DAYS);
  } catch (e) {
    console.log(e);
  }
};

// 清除所有缓存数据
export const clearAll = () => {
  clearAddress();
  clearArea();
}
