// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25238
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25238
import { eventTrackingUtils } from "@/utils/eventTrackingUtils";
interface IExpoTrackParamsRecord {
  /** 继续按钮曝光 */
  NewLoginPopup_ContinueExpo: undefined;
  /** 谷歌账号快速登录曝光 */
  NewLoginPopup_GoogleExpo: undefined;
  /** facebook账号快速登录曝光 */
  NewLoginPopup_FacebookExpo: undefined;
  /** 苹果账号快速登录曝光 */
  NewLoginPopup_AppleExpo: undefined;
  /** 隐私政策按钮曝光 */
  NewLoginPopup_PrivacyExpo: undefined;
  /** 用户成功登录结果返回 */
  NewLoginPopup_LoginResult: {
    /** 登录注册类型 1-phone,2-email,3-谷歌账户,4-facebook账户,5-京东账户,6-苹果账户 */
    loginRegisterType: "1" | "2" | "3" | "4" | "5" | "6";
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
    /** 是否成功  */
    is_success: string;
  };
  /** 京东账号快速登录曝光 */
  NewLoginPopup_JDExpo: undefined;
  /** 条款按钮曝光 */
  NewLoginPopup_TermExpo: undefined;
  /** 手机号切换国家曝光 */
  NewLoginPopup_PhoneSwitchExpo: undefined;
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord, string>> = Object.freeze({
  /** 继续按钮曝光 */
  NewLoginPopup_ContinueExpo: "NewLoginPopup_ContinueExpo",
  /** 谷歌账号快速登录曝光 */
  NewLoginPopup_GoogleExpo: "NewLoginPopup_GoogleExpo",
  /** facebook账号快速登录曝光 */
  NewLoginPopup_FacebookExpo: "NewLoginPopup_FacebookExpo",
  /** 苹果账号快速登录曝光 */
  NewLoginPopup_AppleExpo: "NewLoginPopup_AppleExpo",
  /** 隐私政策按钮曝光 */
  NewLoginPopup_PrivacyExpo: "NewLoginPopup_PrivacyExpo",
  /** 用户成功登录结果返回 */
  NewLoginPopup_LoginResult: "NewLoginPopup_LoginResult",
  /** 京东账号快速登录曝光 */
  NewLoginPopup_JDExpo: "NewLoginPopup_JDExpo",
  /** 条款按钮曝光 */
  NewLoginPopup_TermExpo: "NewLoginPopup_TermExpo",
  /** 手机号切换国家曝光 */
  NewLoginPopup_PhoneSwitchExpo: "NewLoginPopup_PhoneSwitchExpo",
});

interface IClickTrackParamsRecord {
  /** 继续按钮点击 */
  NewLoginPopup_Continue: {
    /** 登录注册类型 1-phone,2-email,3-谷歌账户,4-facebook账户,5-京东账户,6-苹果账户 */
    loginRegisterType: "1" | "2" | "3" | "4" | "5" | "6";
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 谷歌账号快速登录 */
  NewLoginPopup_Google: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** facebook账号快速登录 */
  NewLoginPopup_Facebook: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 苹果账号快速登录 */
  NewLoginPopup_Apple: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 隐私政策按钮点击 */
  NewLoginPopup_Privacy: undefined;
  /** 京东账号快速登录 */
  NewLoginPopup_JD: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 条款按钮点击 */
  NewLoginPopup_Term: undefined;
  /** 手机号切换国家点击 */
  NewLoginPopup_PhoneSwitch: undefined;
}

const sendClickTrack = {
  /** 点击事件上报-继续按钮点击 */
  NewLoginPopup_Continue: (json_param: IClickTrackParamsRecord["NewLoginPopup_Continue"]) => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_Continue", json_param });
  },
  /** 点击事件上报-谷歌账号快速登录 */
  NewLoginPopup_Google: (json_param: IClickTrackParamsRecord["NewLoginPopup_Google"]) => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_Google", json_param });
  },
  /** 点击事件上报-facebook账号快速登录 */
  NewLoginPopup_Facebook: (json_param: IClickTrackParamsRecord["NewLoginPopup_Facebook"]) => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_Facebook", json_param });
  },
  /** 点击事件上报-苹果账号快速登录 */
  NewLoginPopup_Apple: (json_param: IClickTrackParamsRecord["NewLoginPopup_Apple"]) => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_Apple", json_param });
  },
  /** 点击事件上报-隐私政策按钮点击 */
  NewLoginPopup_Privacy: () => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_Privacy" });
  },
  /** 点击事件上报-京东账号快速登录 */
  NewLoginPopup_JD: (json_param: IClickTrackParamsRecord["NewLoginPopup_JD"]) => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_JD", json_param });
  },
  /** 点击事件上报-条款按钮点击 */
  NewLoginPopup_Term: () => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_Term" });
  },
  /** 点击事件上报-手机号切换国家点击 */
  NewLoginPopup_PhoneSwitch: () => {
    eventTrackingUtils.submitCL({ event_id: "NewLoginPopup_PhoneSwitch" });
  },
};

export { EXPOSURE_RECORD, sendClickTrack };
export type { IExpoTrackParamsRecord };
