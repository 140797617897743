/* eslint-disable @typescript-eslint/no-explicit-any */
import to from "await-to-js";
import * as T from "./index.interface";
import colorAxiosInstance from "@/common-components-src/api/new-color-axiosInstance";
import { getEncryptDefaultString } from "../../js/utils/login/loginUtils";

// color 网关请求接口案例
export const test = async (requestData: any): Promise<T.RequestTo<any>> => {
  return await to(
    colorAxiosInstance
      .post<T.RequestTo<any>>("", requestData)
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 获取 sessionid
export const getSessionID = (requestData: T.sessionProps): Promise<T.Response<T.sessionIdResponse>> => {
  return colorAxiosInstance
    .post<T.Response<T.sessionIdResponse>>("user_account_getCaptchaSessionId", undefined, { params: { ...requestData } })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      console.log("sxt-err", err);
    });
};

// 判断是新用户还是老用户
export const judgeNewOrOldUserApi = (requestData: T.NewOrOldUserRequest): Promise<T.Response<boolean>> => {
  const { identifier } = requestData;
  return colorAxiosInstance
    .post<T.Response<boolean>>("user_account_verifyAccount", undefined, {
      params: {
        ...requestData,
        identifier: getEncryptDefaultString(identifier),
      },
    })
    .then((response: any) => response.data)
    .catch((err: any) => {});
};
// 发送验证码
export const sendOTP = async (requestData: T.SendCodeType): Promise<T.RequestTo<T.Response<string>>> => {
  const { identifier } = requestData;
  return await to<T.Response<string>>(
    colorAxiosInstance
      .post<T.RequestTo<T.Response<string>>>("user_account_sendVerifyCode", undefined, {
        params: {
          ...requestData,
          identifier: getEncryptDefaultString(identifier),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

/**
 * @desc 登录-OTP账号(手机号或邮箱)验证码注册或登录（B/C端）
 * @param param                 {Object}                入参对象
 * @param param.otpType         {LoginTypeEnum}         登录类型枚举：1-账号登录 2-手机号登录 3-邮箱登录 5-微信登录 6-微信小程序 23-Apple登录 24-Facebook登录 26-Google登录
 * @param param.idPrefix        {String}                手机号国际区号
 * @param param.identifier      {String}                手机号或邮箱账号
 * @param param.verifyCode      {String}                验证码
 * @param param.sessionId       {String}                SessionId
 * @param param.appId           {String}                应用ID
 * @param param.stepCode        {String}                步骤码
 * @returns
 */
// 验证码注册或登录
export const loginOrRegisterByOTP = async (requestData: any): Promise<T.RequestTo<T.Response<any>>> => {
  const { identifier } = requestData;
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_loginByVerifyCode", undefined, {
        params: {
          ...requestData,
          identifier: getEncryptDefaultString(identifier),
        },
        cthr: "1",
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 邮箱注册
export const EmailRegister = async (requestData: T.VerifyEmailRegisterParams): Promise<T.RequestTo<T.Response<any>>> => {
  const { email, password } = requestData;
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_regOrLoginByPwd", undefined, {
        params: {
          ...requestData,
          email: getEncryptDefaultString(email),
          password: getEncryptDefaultString(password),
        },
        cthr: "1",
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};
// 邮箱登录
export const EmailLoginOTP = async (requestData: T.VerifyEmailLoginParams): Promise<T.RequestTo<T.Response<any>>> => {
  const { identifier, password } = requestData;
  const data: any = {
    ...requestData,
    email: getEncryptDefaultString(identifier),
    password: getEncryptDefaultString(password),
  }
  delete data.identifier
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_regOrLoginByPwd", undefined, {
        params: data,
        cthr: "1",
      })
      .then((response: any) => {
        return response.data;
      })
      .catch((err: any) => {}),
  );
};

// 判断当前邮箱是否绑定手机号
export const emailBindingPhone = async (requestData: any): Promise<T.RequestTo<T.Response<any>>> => {
  const { identifier } = requestData;
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_verifyBinding", undefined, {
        params: {
          ...requestData,
          identifier: getEncryptDefaultString(identifier),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 邮箱忘记密码 校验验证码
export const checkEmailCodeApi = async (requestData: T.verifyCodeCheckRequest): Promise<T.RequestTo<T.Response<string>>> => {
  const { email, scene, otpType, stepCode, optVerifyCode } = requestData;
  return await to<T.Response<string>>(
    colorAxiosInstance
      .post<T.Response<string>>("user_account_checkVerifyCode", undefined, {
        params: {
          identifier: getEncryptDefaultString(email),
          scene,
          otpType,
          stepCode,
          optVerifyCode,
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 邮箱找回密码
export const retrievePasswordApi = async (requestData: any): Promise<T.RequestTo<T.Response<string>>> => {
  const { password } = requestData;
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_resetPassword", undefined, {
        params: {
          ...requestData,
          password: getEncryptDefaultString(password),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 获取心智平台数据
/*
    HOME_PAGE(1, "Home Page"),
    CATEGORY(2, "Category"),
    CART(3, "Cart"),
    ME(4, "Me"),
    SEARCH(5, "Search"),
    CHECKOUT_PAGE(6, "Checkout Page"),
    SIGN_IN_REGISTER(7, "Sign In/Register");`
**/
function stringify(obj: Record<string, any>): any {
  return Object.keys(obj)
    .map((key) => {
      const value = obj[key];
      if (value === null || value === undefined) {
        return encodeURIComponent(key);
      }
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
}
export const getMindData = (scope: number): Promise<any> => {
  return colorAxiosInstance.post<any>(`user_mind_get`, undefined, { params: { mindScope: scope } }).then((response) => {
    if (response?.status === 200 && response.data) {
      return response.data;
    }
  });
};

// 第三方账号跳转登录授权 & 独立站授权登录
/**
 * 在调用这个接口前需要先调用 jnos.account.authorizeInfo.get获取到state、并调用三方平台的SDK或页面跳转获取到 code/accessToken/idToken中的一个，入参包含  code/accessToken/idToken、state、loginType
 */
export const thirdLoginApi = async (requestData: any): Promise<T.RequestTo<T.Response<string>>> => {
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_loginByOuterAuth", undefined, { params: { ...requestData }, cthr: "1" })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 获取三方账号授权地址(Obtain the third-party account authorization address)
export const getThirdAppAuthorizeUrl = async (requestData: any): Promise<T.RequestTo<T.Response<string>>> => {
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_getOuterAuth", undefined, {
        params: { ...requestData },
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "*",
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

/* 获取登录配置 */
export const getLoginStaticConfig = async (requestData: any): Promise<T.RequestTo<T.Response<string>>> => {
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("basic_account_getLoginConfig", undefined, { params: { ...requestData } })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};
// 退出登录
export const outLoginToC = async (params: any): Promise<T.RequestTo<T.Response<string>>> => {
  return await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_logout", undefined, {
        cthr: "1",
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 获取用户信息
export const getUserInfoApi = async (requestParams: any, config: any) => {
  const res = await to<T.Response<any>>(
    colorAxiosInstance
      .get<T.Response<any>>("user_account_get", undefined, {
        params: requestParams,
        ...config
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
  return res;
};

// 获取国家手机号数据
export const getCountryListApi = async () => {
  const res = await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_getCountryList", undefined)
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
  return res;
};

// 获取登录态用户信息
export const getLoginInfoApi = async (requestParams: any, config: any) => {
  const res = await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_getLoginInfo", undefined ,{
        params: requestParams,
        ...config
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
  return res;
};
