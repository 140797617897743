/* eslint-disable react/no-unknown-property */
// 输入框选择框样式
export const InputStyle = {
  "& label": {
    color: "#505259",
    // transform: "translate(65px, 16px) scale(1)",
  },
  "& label.Mui-focused": {
    color: "#505259",
    // transform: "translate(14px, -9px) scale(0.75)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: "#1a1a1a",
      borderStyle: "solid",
    },
  },
};

export const inputDefaultSx = {
  width: "100%", // 设置宽度
  "& .MuiInputLabel-root": {
    zIndex: "15",
    backgroundColor: "#fff",
  },
  "& label": {
    color: "#505259",
    transform: "translate(65px, 16px) scale(1)",
  },
  "& label.Mui-focused": {
    color: "#505259",
    transform: "translate(14px, -9px) scale(0.75)",
  },
  "& label.MuiInputLabel-shrink": {
    color: "#505259",
    transform: "translate(14px, -9px) scale(0.75)",
  },
}
export const inputRootDefaultSx = {
  height: "55px",
  paddingLeft: "0",
  paddingRight: "0",
  overflow: "hidden",
  whiteSpace: "nowrap",
}

export const continueBtnSx = {
  width: "380px",
  height: "48px",
  fontSize: "20px",
  margin: "25px 0",
  backgroundColor: "#CC0C1C", // 自定义背景颜色
  color: "#fff", // 自定义文字颜色
  textTransform: "none",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "#990915", // 悬停时的背景颜色
  },
  "&.Mui-disabled": {
    backgroundColor: "#FF9FA7",
    color: "#fff",
  },
}

export const SelectStyle = {
  "& .MuiInputBase-input": {
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
  },
  "& .MuiInputBase-formControl": {
    borderRadius: "0px",
  },
  "& fieldset": {
    borderColor: "#00000000 !important",
  },
  "& .Mui-focused fieldset": {
    borderColor: "#00000000 !important",
  },
};

export const BoxStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

export const FormControlInputStyle = {
  width: "400px",
  margin: "15px 0",
  "& .MuiInputBase-root": {
    height: "48px",
  },
  "& .MuiFormLabel-root": {
    padding: "0 5px",
    backgroundColor: "#FFF !important",
  },
  "& .MuiFormHelperText-root": {
    margin: "3px 0 0"
  }
};

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null, // 确保菜单从顶部对齐
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
        sx: {
          width: '380px',
          "& .Mui-selected": {
            color: "#CC0C1C !important",
            backgroundColor: "#F9F9F9 !important",
          },
          "::-webkit-scrollbar": {width: "0 !important"},
          "& .MuiMenuItem-root": {
              height: "38px",
              fontSize: "14px",
              lineHeight: "38px",
              color: "#333",
              fontWeight: "400",
              borderBottom: "1px solid #eee",
          }
        }
    },
};