// 广告归因接口白名单
const ATTRIBUTION_WHITE_LIST: Record<string, boolean> = {
  user_account_regOrLoginByPwd: false, // 邮箱注册或登录
  user_account_loginByVerifyCode: false, // 验证码注册或登录
  user_account_loginByOuterAuth: false, // 第三方账号跳转登录授权 & 独立站授权登录
  trade_cart_addItem: false, // 购物车加车
  trade_checkout_submit: true, // 下单接口
}

export default function isInWhiteList(keyName: string){
  if(ATTRIBUTION_WHITE_LIST[keyName]){
    return true;
  }
  return false;
}
