import React from "react";
import style from "../../style.module.scss";
import { Button } from "@mui/material";

const ReceiveTheCodeDom = ({ closeDialog }: { closeDialog: () => void }) => {
  return (
    <div className={style.receiveTheCode_wrap}>
      <div className={style.title}>Didn't receive the code？</div>
      <div className={style.list}>
        <p>
          Hello, the SMS might be delayed. Please wait patiently for a moment. If you still cannot receive the verification code after waiting for a while, we
          recommend the following steps:
        </p>
        <p>1. Check whether your phone number is entered correctly and valid. Ensure it is not an empty or invalid number.</p>
        <p>2. Verify if your phone is in service. Check for issues such as suspension, being turned off, or no signal.</p>
        <p>3. Check your spam or blocked messages folder to ensure the SMS hasn’t been filtered.</p>
      </div>
      <div className={style.footer}>
        <Button
          sx={{
            width: "74px",
            height: "32px",
            fontSize: "16px",
            margin: "25px 0",
            color: "#fff", // 自定义文字颜色
            backgroundColor: "#CC0C1C", // 自定义背景颜色
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#990915", // 悬停时的背景颜色
            },
            "&.Mui-disabled": {
              backgroundColor: "#FF9FA7",
              color: "#fff",
            },
          }}
          onClick={() => closeDialog && closeDialog()}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default ReceiveTheCodeDom;
