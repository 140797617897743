// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25277
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25277
import { eventTrackingUtils } from "@/utils/eventTrackingUtils";
interface IExpoTrackParamsRecord {
  /** 编辑号码曝光 */
  MsgCode_EditExpo: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 未收到验证码曝光 */
  MsgCode_NOTRecieveExpo: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 再次发送按钮点击曝光 */
  MsgCode_ResendExpo: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 注册按钮曝光 */
  MsgCode_RegisterExpo: undefined;
  /** 登录按钮曝光 */
  MsgCode_SignInExpo: undefined;
  /** 挽留弹窗曝光 */
  MsgCode_RetainExpo: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord, string>> = Object.freeze({
  /** 编辑号码曝光 */
  MsgCode_EditExpo: "MsgCode_EditExpo",
  /** 未收到验证码曝光 */
  MsgCode_NOTRecieveExpo: "MsgCode_NOTRecieveExpo",
  /** 再次发送按钮点击曝光 */
  MsgCode_ResendExpo: "MsgCode_ResendExpo",
  /** 注册按钮曝光 */
  MsgCode_RegisterExpo: "MsgCode_RegisterExpo",
  /** 登录按钮曝光 */
  MsgCode_SignInExpo: "MsgCode_SignInExpo",
  /** 挽留弹窗曝光 */
  MsgCode_RetainExpo: "MsgCode_RetainExpo",
});

interface IKeyActionTrackParamsRecord {
  /** 验证结果返回 */
  MsgCode_VerifyResult: {
    /** 是否成功  */
    is_success: string;
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
}

const sendKeyActionTrack = {
  /** 关键事件上报-验证结果返回 */
  MsgCode_VerifyResult: (json_param: IKeyActionTrackParamsRecord["MsgCode_VerifyResult"]) => {
    eventTrackingUtils.submitExp({ event_id: "MsgCode_VerifyResult", json_param });
  },
};

interface IClickTrackParamsRecord {
  /** 编辑号码 */
  MsgCode_Edit: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 未收到验证码 */
  MsgCode_NOTRecieve: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 再次发送按钮点击 */
  MsgCode_Resend: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 注册按钮 */
  MsgCode_Register: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 登录按钮 */
  MsgCode_SignIn: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 挽留弹窗-继续注册 */
  MsgCode_RetainExpo_Continue: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 挽留弹窗-离开 */
  MsgCode_RetainExpo_Leave: {
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
  /** 用户登录结果返回 */
  MsgCode_LoginResult: {
    /** 是否成功  */
    is_success: string;
    /** 是否为新用户 1-是,0-否 */
    isNew: "1" | "0";
  };
}

const sendClickTrack = {
  /** 点击事件上报-编辑号码 */
  MsgCode_Edit: (json_param: IClickTrackParamsRecord["MsgCode_Edit"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_Edit", json_param });
  },
  /** 点击事件上报-未收到验证码 */
  MsgCode_NOTRecieve: (json_param: IClickTrackParamsRecord["MsgCode_NOTRecieve"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_NOTRecieve", json_param });
  },
  /** 点击事件上报-再次发送按钮点击 */
  MsgCode_Resend: (json_param: IClickTrackParamsRecord["MsgCode_Resend"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_Resend", json_param });
  },
  /** 点击事件上报-注册按钮 */
  MsgCode_Register: (json_param: IClickTrackParamsRecord["MsgCode_Register"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_Register", json_param });
  },
  /** 点击事件上报-登录按钮 */
  MsgCode_SignIn: (json_param: IClickTrackParamsRecord["MsgCode_SignIn"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_SignIn", json_param });
  },
  /** 点击事件上报-挽留弹窗-继续注册 */
  MsgCode_RetainExpo_Continue: (json_param: IClickTrackParamsRecord["MsgCode_RetainExpo_Continue"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_RetainExpo_Continue", json_param });
  },
  /** 点击事件上报-挽留弹窗-离开 */
  MsgCode_RetainExpo_Leave: (json_param: IClickTrackParamsRecord["MsgCode_RetainExpo_Leave"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_RetainExpo_Leave", json_param });
  },
  /** 点击事件上报-用户登录结果返回 */
  MsgCode_LoginResult: (json_param: IClickTrackParamsRecord["MsgCode_LoginResult"]) => {
    eventTrackingUtils.submitCL({ event_id: "MsgCode_LoginResult", json_param });
  },
};

export { EXPOSURE_RECORD, sendClickTrack, sendKeyActionTrack };
export type { IExpoTrackParamsRecord };
