// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25225
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25225 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
export const PageId = Object.freeze({
  NewLogin: 'NewLogin'
})

interface IPVTrackParamsRecord {
  /** 登录注册页 */
NewLogin: undefined
}

const sendPv = {
  /** PV-登录注册页 */
NewLogin: () => {
    eventTrackingUtils.submitPV({page_id:PageId.NewLogin});
  },
}

interface IExpoTrackParamsRecord {
  /** 继续按钮曝光 */
NewLogin_ContinueExpo: undefined
  /** 谷歌账号快速登录曝光 */
NewLogin_GoogleExpo: undefined
  /** facebook账号快速登录曝光 */
NewLogin_FacebookExpo: undefined
  /** 苹果账号快速登录曝光 */
NewLogin_AppleExpo: undefined
  /** 隐私政策按钮曝光 */
NewLogin_PrivacyExpo: undefined
  /** 京东账号快速登录曝光 */
NewLogin_JDExpo: undefined
  /** 条款按钮曝光 */
NewLogin_TermExpo: undefined
  /** 手机号切换国家曝光 */
NewLogin_PhoneSwitchExpo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 继续按钮曝光 */
  NewLogin_ContinueExpo:'NewLogin_ContinueExpo',
/** 谷歌账号快速登录曝光 */
  NewLogin_GoogleExpo:'NewLogin_GoogleExpo',
/** facebook账号快速登录曝光 */
  NewLogin_FacebookExpo:'NewLogin_FacebookExpo',
/** 苹果账号快速登录曝光 */
  NewLogin_AppleExpo:'NewLogin_AppleExpo',
/** 隐私政策按钮曝光 */
  NewLogin_PrivacyExpo:'NewLogin_PrivacyExpo',
/** 京东账号快速登录曝光 */
  NewLogin_JDExpo:'NewLogin_JDExpo',
/** 条款按钮曝光 */
  NewLogin_TermExpo:'NewLogin_TermExpo',
/** 手机号切换国家曝光 */
  NewLogin_PhoneSwitchExpo:'NewLogin_PhoneSwitchExpo',
})

interface IKeyActionTrackParamsRecord {
  /** 用户成功登录结果返回 */
NewLogin_LoginResult: {
/** 登录注册类型 1-phone,2-email,3-谷歌账户,4-facebook账户,5-京东账户,6-苹果账户 */
loginRegisterType: | '1'| '2'| '3'| '4'| '5'| '6',
/** 是否成功  */
is_success: string,
/** 是否为新用户 1-是,0-否 */
isNew: | '1'| '0'}
}

const sendKeyActionTrack = {
  /** 关键事件上报-用户成功登录结果返回 */
NewLogin_LoginResult: (json_param: IKeyActionTrackParamsRecord['NewLogin_LoginResult']) => {
    eventTrackingUtils.submitExp({event_id:'NewLogin_LoginResult',json_param});
  },
}

interface IClickTrackParamsRecord {
  /** 继续按钮点击 */
NewLogin_Continue: {
/** 登录注册类型 1-phone,2-email,3-谷歌账户,4-facebook账户,5-京东账户,6-苹果账户 */
loginRegisterType: | '1'| '2'| '3'| '4'| '5'| '6',
/** 是否为新用户 1-是,0-否 */
isNew: | '1'| '0'}
  /** 谷歌账号快速登录 */
NewLogin_Google: undefined
  /** facebook账号快速登录 */
NewLogin_Facebook: undefined
  /** 苹果账号快速登录 */
NewLogin_Apple: undefined
  /** 隐私政策按钮点击 */
NewLogin_Privacy: undefined
  /** 京东账号快速登录 */
NewLogin_JD: undefined
  /** 条款按钮点击 */
NewLogin_Term: undefined
  /** 手机号切换国家点击 */
NewLogin_PhoneSwitch: undefined
}

const sendClickTrack = {
  /** 点击事件上报-继续按钮点击 */
NewLogin_Continue: (json_param: IClickTrackParamsRecord['NewLogin_Continue']) => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_Continue',json_param});
  },
  /** 点击事件上报-谷歌账号快速登录 */
NewLogin_Google: () => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_Google'});
  },
  /** 点击事件上报-facebook账号快速登录 */
NewLogin_Facebook: () => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_Facebook'});
  },
  /** 点击事件上报-苹果账号快速登录 */
NewLogin_Apple: () => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_Apple'});
  },
  /** 点击事件上报-隐私政策按钮点击 */
NewLogin_Privacy: () => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_Privacy'});
  },
  /** 点击事件上报-京东账号快速登录 */
NewLogin_JD: () => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_JD'});
  },
  /** 点击事件上报-条款按钮点击 */
NewLogin_Term: () => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_Term'});
  },
  /** 点击事件上报-手机号切换国家点击 */
NewLogin_PhoneSwitch: () => {
    eventTrackingUtils.submitCL({event_id:'NewLogin_PhoneSwitch'});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
  sendKeyActionTrack,
  sendPv,
}
export type {
  IExpoTrackParamsRecord,
}