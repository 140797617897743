import * as T from "../../type/index";
import loginEnum from "@/common-components-src/const/login";
import LoginDialog from "../Dialog/index";
import cookieUtils from "@/utils/cookieUtils";
import MindDialog from "../mindDialog/MindDialog";
import PhoneEmailLoginPage from "./phoneEmailLoginPage";

import React, { useState, useEffect } from "react";
import { DialogContent } from "@mui/material";
import { getMindData } from "@/common-components-src/api/login/index.api";
interface PropsType {
  isOpen: boolean;
  propsData: T.propsDataType; // 其他数据
  props: { isRefresh: boolean; isForgotPassword: boolean }; // 登录时传入参数 控制是否强制刷新页面等
  closeDialog: () => void;
  callback: (props?: any) => void; // 登录成功后的回调函数
  goLoginDefault: (props?: T.propsDataType) => void; // 跳转到登录页的函数
  ForgotPasswordFn: (props?: T.propsDataType) => void;
}

const PhoneEmailLogin: React.FC<PropsType> = ({ props, callback, isOpen, propsData, goLoginDefault, ForgotPasswordFn, closeDialog }) => {
  const [mindData, setMindData] = useState(null); // 心智数据的弹框
  const [open, setOpen] = useState(true); // 控制弹窗的显示
  const [mindOpen, setMindOpen] = useState(false);

  const __w: any = window;
  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  // 获取心智数据
  const getMindDataFunction = async () => {
    // 如果心智数据不存在，则获取
    try {
      const res = await getMindData(loginEnum.MindScopeEnum.NEW_USER_REGISTER);
      if (res && res.success) {
        // 如果心智数据存在，则设置
        if (res.data && res.data.length > 0) {
          cookieUtils.setSessionStorage(loginEnum.Login.MIND_LOGIN_SESSION_STORAGE, JSON.stringify(res.data?.[0]));
          setMindData(res.data?.[0]);
          setMindOpen(true);
        } else {
          setMindOpen(false);
          closeDialog?.();
        }
      } else {
        // 如果心智数据不存在，则关闭
        setMindOpen(false);
        closeDialog?.();
      }
    } catch (error) { }
  };
  const childrenRef: any = React.useRef(null);
  // 返回上一弹框
  const onReturnFn = () => {
    childrenRef.current && childrenRef.current?.onReturnFn();
    goLoginDefault?.(propsData);
  };
  // 心智弹框
  const closePhoneEmail = async (reason: string) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick" || reason === "close" || reason === "esc") {
      return; // 点击关闭按钮或者按 esc 键时，不执行关闭操作
    }
    // 获取心智！！
    if (propsData && propsData.userType == "new") {
      await getMindDataFunction();
      // closeDialog && closeDialog();
    } else {
      closeDialog?.();
    }
  };

  // 关闭挽留弹框、打开上一登陆页面
  const leaveNowFn = () => {
    setMindOpen(false);
    childrenRef.current && childrenRef.current?.onReturnFn();
    goLoginDefault && goLoginDefault(propsData);
  };

  return (
    <>
      <LoginDialog
        open={open}
        onClose={(e: React.MouseEvent<HTMLButtonElement>, r: string) => closePhoneEmail(r)}
        onReturn={onReturnFn}
        showCloseBtn={true}
        showReturnBtn={true}
        sx={{
          "& .MuiDialog-paper": {
            minWidth: "600px",
            minHeight: "580px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent>
          <PhoneEmailLoginPage
            ref={childrenRef}
            props={props}
            callback={callback}
            propsData={propsData}
            goLoginDefault={goLoginDefault}
            ForgotPasswordFn={ForgotPasswordFn}
            closeDialog={closeDialog}
          />
        </DialogContent>
      </LoginDialog>
      {/* 挽留心智弹框 */}
      <>
        {mindOpen && (
          <MindDialog
            isOpen={mindOpen}
            propsData={propsData}
            leaveNow={leaveNowFn}
            onlyClose={() => setMindOpen(false)}
            mindData={mindData}
          />
        )}
      </>
    </>
  );
};

export default PhoneEmailLogin;
