// 埋点页面链接：https://stream.jd.com/ddrx/#/requirement/25298
// WARNING!!! 请勿手动修改此文件，如需修改请在子午线平台变更后，执行npm run track-init 25298 
import { eventTrackingUtils } from '@/utils/eventTrackingUtils'
interface IExpoTrackParamsRecord {
  /** 注册曝光 */
OldLogin_RegisterExpo: undefined
  /** 登录按钮曝光 */
OldLogin_SignInExpo: undefined
  /** 移除账号按钮曝光 */
OldLogin_RemoveExpo: undefined
  /** 切换账户曝光 */
OldLogin_AnotherAccountExpo: undefined
  /** 隐私政策按钮曝光 */
OldLogin_PrivacyExpo: undefined
  /** 条款按钮曝光 */
OldLogin_TermExpo: undefined
}

const EXPOSURE_RECORD: Readonly<Record<keyof IExpoTrackParamsRecord,string>> = Object.freeze({
/** 注册曝光 */
  OldLogin_RegisterExpo:'OldLogin_RegisterExpo',
/** 登录按钮曝光 */
  OldLogin_SignInExpo:'OldLogin_SignInExpo',
/** 移除账号按钮曝光 */
  OldLogin_RemoveExpo:'OldLogin_RemoveExpo',
/** 切换账户曝光 */
  OldLogin_AnotherAccountExpo:'OldLogin_AnotherAccountExpo',
/** 隐私政策按钮曝光 */
  OldLogin_PrivacyExpo:'OldLogin_PrivacyExpo',
/** 条款按钮曝光 */
  OldLogin_TermExpo:'OldLogin_TermExpo',
})

interface IClickTrackParamsRecord {
  /** 登录按钮点击 */
OldLogin_SignIn: {
/** 登录注册类型 1-phone,2-email,3-谷歌账户,4-facebook账户,5-京东账户,6-苹果账户 */
loginRegisterType: | '1'| '2'| '3'| '4'| '5'| '6'}
  /** 移除账号按钮点击 */
OldLogin_Remove: undefined
  /** 切换账户点击 */
OldLogin_AnotherAccount: undefined
  /** 隐私政策按钮点击 */
OldLogin_Privacy: undefined
  /** 条款按钮点击 */
OldLogin_Term: undefined
}

const sendClickTrack = {
  /** 点击事件上报-登录按钮点击 */
OldLogin_SignIn: (json_param: IClickTrackParamsRecord['OldLogin_SignIn']) => {
    eventTrackingUtils.submitCL({event_id:'OldLogin_SignIn',json_param});
  },
  /** 点击事件上报-移除账号按钮点击 */
OldLogin_Remove: () => {
    eventTrackingUtils.submitCL({event_id:'OldLogin_Remove'});
  },
  /** 点击事件上报-切换账户点击 */
OldLogin_AnotherAccount: () => {
    eventTrackingUtils.submitCL({event_id:'OldLogin_AnotherAccount'});
  },
  /** 点击事件上报-隐私政策按钮点击 */
OldLogin_Privacy: () => {
    eventTrackingUtils.submitCL({event_id:'OldLogin_Privacy'});
  },
  /** 点击事件上报-条款按钮点击 */
OldLogin_Term: () => {
    eventTrackingUtils.submitCL({event_id:'OldLogin_Term'});
  },
}

export {
  EXPOSURE_RECORD,
  sendClickTrack,
}
export type {
  IExpoTrackParamsRecord,
}