// 解构 传入的对象，返回路径拼接的字符串 
export function objectToUrlParams(obj: any) {
  return Object.keys(obj)
    .map((key) => {
      if (obj[key] === null || obj[key] === undefined) {
        return encodeURIComponent(key) + "=";
      }
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    })
    .join("&");
}
